<template>
  <div id="project-data-page">
    <PageTitle icon :title="title" :loading="loading" />
    <FilterDataList :id="id" />
  </div>
</template>

<script>
import orphanModule from '../store'
import PageTitle from '@/components/common/PageTitle.vue'
import FilterDataList from '../entity/filter/FilterDataList.vue'

export default {
  name: 'FilterDataView',
  components: { PageTitle, FilterDataList },
  props: {
    id: [Number, String],
  },
  computed: {
    ...orphanModule.mapGetters(['storedFilterName', 'referencesLoading']),
    title() {
      return this.storedFilterName || 'Фильтр...'
    },
    loading() {
      // загрузка пока нет названия или грузятся справочники
      return !this.storedFilterName || this.referencesLoading
    },
  },
}
</script>
