import api from '@/lib/axiosApi'

export class EApiFiltersException extends Error {
  constructor(message) {
    super(`[filters] ${message}`)
    this.name = 'EApiFiltersException'
  }
  static check(filter) {
    if (!filter) {
      throw new this('Фильтр не может быть пустым')
    }
  }
  static checkId(filter) {
    if ((typeof filter === 'object' && !filter?.id) || !filter) {
      throw new this('Фильтр не определён (отсутствует id)')
    }
  }
}

export const filters = {
  getFilters: () => {
    return api.get(`orphan/filters/`)
  },

  getFilter: id => {
    EApiFiltersException.checkId(id)
    return api.get(`orphan/filters/${id}`)
  },

  createFilter: filter => {
    EApiFiltersException.check(filter)
    return api.post('orphan/filters/', filter)
  },

  editFilter: filter => {
    EApiFiltersException.checkId(filter)
    return api.patch(`orphan/filters/${filter.id}`, filter)
  },

  deleteFilter: filter => {
    EApiFiltersException.checkId(filter)
    return api.delete(`orphan/filters/${filter.id}`)
  },
}

export default filters
