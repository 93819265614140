/** Функция фильтрации данных
 * @param  {String|Object|Boolean}  item     Входной объект
 * @param  {String|Array}           field    Искомое значение
 * @param  {Boolean}                strict   Флаг строгой проверки - полное совпадение (строк)
 * @param  {String}                 positive Позитивное значение ответа, для логического фильтра
 * @return {Boolean}                def  По умолчанию true - если под фильтр ничего не попало
 * @return {*}                      true если фильтр совпадает. false - не совпадает
 */
export function customFilterFn(
  itemField,
  filterValue,
  strict = false,
  positive = ''
) {
  // Это логичесого состояния фильтр?
  if (typeof itemField === 'boolean' && positive) {
    if (filterValue === positive && itemField) return true
    if (filterValue !== positive && !itemField) return true
    return false
  }
  if (!itemField || !filterValue) return true

  const itemText = itemField.toUpperCase()

  if (typeof filterValue === 'string') {
    return strict
      ? itemText === filterValue.toUpperCase()
      : itemText.includes(filterValue.toUpperCase())
  } else if (Array.isArray(filterValue)) {
    if (!filterValue.length) return true

    let res = false
    filterValue.forEach(value => {
      res = res || !!itemText.includes(value.toUpperCase())
    })
    return res
  }
  // для будущих типов.
  return true
}
