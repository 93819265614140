<template>
  <router-view />
</template>

<script>
import orphanModule from '../store'

export default {
  name: 'OrphanView',
  beforeCreate() {
    orphanModule.register()
  },
  destroyed() {
    orphanModule.unregister()
  },
}
</script>
