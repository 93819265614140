<template>
  <ActionDialog
    v-if="valid"
    v-bind="$attrs"
    v-on="$listeners"
    width="600px"
    title="Внимание! Данные не сохранены!"
    label="Сохранить внесённые изменения?"
    labelBtnAction="СОХРАНИТЬ"
    colorBtnAction="primary"
    showSecondBtn
    labelBtnSecondAction="НЕ СОХРАНЯТЬ"
    labelBtnClose="ОТМЕНА"
  />
  <ActionDialog
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    width="600px"
    title="Внимание! Данные не сохранены!"
    label="Закрыть без сохранения данных?"
    hideActionBtn
    showSecondBtn
    labelBtnSecondAction="НЕ СОХРАНЯТЬ"
    colorBtnSecondAction="error"
    labelBtnClose="ОТМЕНА"
    ><template #text>Внесённые изменения будут потеряны.</template>
  </ActionDialog>
  <!-- Отменить изменения и закрыть форму, или продол&shy;жить редактирование? -->
</template>

<script>
import ActionDialog from '@/components/ui/dialogs/ActionDialog.vue'

export default {
  name: 'UnSavedAlertDialog',
  inheritAttrs: false,
  components: { ActionDialog },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    // Переключает диалог с возможностью сохранения
    // на предупреждения при не корректно введённых данных.
    valid: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
