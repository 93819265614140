<template>
  <v-container fluid v-bind="$attrs">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sources"
          :hide-default-footer="hideFooter"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
        >
          <template #[`header.default_age`]="{ header }">
            <span :title="header?.title || ''">{{ header.text }}</span>
          </template>

          <template #[`header.actions`]="{ header }">
            <span :title="header.text">
              <v-icon v-if="header?.icon">
                {{ header?.icon }}
              </v-icon>
            </span>
          </template>

          <template
            v-for="head in simpleFieldsHeaders"
            v-slot:[`item.${head.value}`]="props"
          >
            <td
              :class="[
                `${
                  props.item[head.value] && head?.align
                    ? 'text-' + head.align
                    : ''
                }`,
                { 'text-center': !props.item[head.value] },
              ]"
              :key="`item-${head.value}`"
            >
              <v-edit-dialog
                large
                save-text="Применить"
                cancel-text="Отменить"
                @save="save(props.item, head.value, getRule(head.rule))"
                @open="editModel[head.value] = props.item[head.value]"
              >
                <span :class="head?.cellClass">
                  {{ props.item[head.value] || '—' | truncate }}
                </span>
                <template v-slot:input>
                  <v-text-field
                    v-model="editModel[head.value]"
                    :rules="getRule(head.rule)"
                    label="Редактировать"
                    single-line
                    clearable
                    counter
                    autofocus
                  />
                </template>
              </v-edit-dialog>
            </td>
          </template>

          <template #[`item.date`]="props">
            <v-edit-dialog
              large
              cancel-text="Отменить"
              save-text="Применить"
              @save="save(props.item, 'date')"
              @open="editModel.date = getDateForPicker(props.item.date)"
            >
              <span :title="props.item.date">
                {{ props.item.date || '—' }}
              </span>
              <template v-slot:input>
                <v-date-picker v-model="asDatePicker" locale="ru-ru" />
              </template>
            </v-edit-dialog>
          </template>

          <template #[`item.default_age`]="props">
            <v-edit-dialog
              large
              disabled
              cancel-text="Отменить"
              save-text="Применить"
              @save="save(props.item, 'default_age')"
              @open="editModel.default_age = props.item.default_age"
            >
              {{ getDeafaultAge(props.item.default_age) || '—' }}
              <template v-slot:input>
                <v-select
                  v-model="editModel.default_age"
                  :label="props.header.text"
                  :hint="props.header.title"
                  :items="ageCategories"
                  item-text="title"
                  item-value="id"
                  class="mt-2"
                />
              </template>
            </v-edit-dialog>
          </template>

          <template #[`item.icon`]="props">
            <v-edit-dialog
              large
              cancel-text="Отменить"
              save-text="Применить"
              @save="save(props.item, 'icon')"
              @open="editModel.icon = props.item.icon"
            >
              <v-icon v-if="props.item?.icon">
                {{ props.item?.icon }}
              </v-icon>
              <span v-else> — </span>
              <template v-slot:input>
                <v-select
                  v-model="editModel.icon"
                  :items="icons"
                  class="mt-2"
                  label="Иконка"
                  hint="Иконка для источника"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon v-if="item">
                      {{ item }}
                    </v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon v-if="item">
                      {{ item }}
                    </v-icon>
                  </template>
                </v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template #[`item.actions`]="{ item }">
            <span class="d-flex justify-end actions">
              <SourceDeleteDialog
                :source="item"
                @onAction="deleteSourceConfirm(item)"
              >
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon dense color="red">
                    <v-icon size="18">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </SourceDeleteDialog>
            </span>
          </template>
          <!-- ПОДВАЛ ТАБЛИЦЫ -->
          <template v-slot:footer>
            <div class="py-0 text-right">
              <v-fab-transition>
                <v-btn
                  fab
                  x-small
                  elevation="4"
                  class="ma-2 white--text"
                  color="pink"
                  title="Добавить источник"
                  @click="createSource"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const DEFAULT_SOURCE = {
  short_name: 'Новый источник',
  full_name: '',
  description: null,
  date: null,
  icon: '',
  default_age: null,
}

import { DEFAULT_TRUNCATE_LEN_SOURCES } from '../../../../config/constants'
import DateForPicker from '@/lib/datetime'
import SourceDeleteDialog from './SourceDeleteDialog.vue'
export default {
  name: 'FilterSourcesEditor',
  components: { SourceDeleteDialog },
  model: {
    prop: 'sources',
    event: 'input',
  },
  props: {
    sources: {
      type: Array,
      required: false,
    },
    ageCategories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Краткое наименование',
        align: 'start',
        sortable: false,
        value: 'short_name',
        simpleField: true,
        rule: 'rulesShortName',
        cellClass: 'font-weight-medium',
      },
      {
        text: 'Полное наименование',
        align: 'start',
        sortable: false,
        value: 'full_name',
        simpleField: true,
        rule: 'rulesFullName',
      },
      {
        text: 'Дополнительно',
        width: '150px',
        align: 'start',
        sortable: false,
        value: 'description',
        simpleField: true,
        rule: 'rulesFullName',
      },
      {
        text: 'Дата',
        align: 'center',
        sortable: false,
        value: 'date',
      },
      {
        text: 'Иконка',
        align: 'center',
        sortable: false,
        value: 'icon',
      },
      {
        text: 'Возрастная группа',
        title: 'Возрастная группа по умолчанию для источника',
        align: 'center',
        sortable: false,
        value: 'default_age',
      },
      {
        text: 'Действия',
        width: '60px',
        align: 'end',
        sortable: false,
        value: 'actions',
        icon: 'mdi-dots-horizontal',
      },
    ],
    icons: [
      'mdi-medication-outline',
      'mdi-alpha-v-circle-outline',
      'mdi-heart-circle-outline',
      'mdi-pill',
      'mdi-human',
      'mdi-human-male-female-child',
      'mdi-human-cane',
      'mdi-vector-square',
      'mdi-dolphin',
      'mdi-ruler-square-compass',
      'mdi-silverware',
      'mdi-dots-horizontal-circle-outline',
      'mdi-account-school-outline',
    ],
    ruleNotEmpty: v => !!v || 'Поле должно быть заполнено',
    ruleMinLen: v =>
      v?.length >= 3 || 'Поле должно содержать не менее 3х символов',
    ruleMaxLen32: v =>
      v?.length <= 64 || 'Поле должно содержать менее 32 символов',
    ruleMaxLen128: v =>
      v?.length <= 256 || 'Поле должно содержать менее 128 символов',
    ageCatRules: [v => !!v || 'Требуется указать возрастную группу'],
    editModel: {
      date: null,
    },
    page: 1,
    itemsPerPage: 10,
  }),

  computed: {
    hideFooter() {
      return this.sources?.length <= 10
    },
    rulesShortName() {
      return [this.ruleNotEmpty, this.ruleMinLen, this.ruleMaxLen32]
    },
    rulesFullName() {
      return [this.ruleMaxLen128]
    },
    asDatePicker: {
      get() {
        return this.getDateForPicker(this.editModel.date)
      },
      set(val) {
        this.editModel.date = this.setDateForPicker(val)
      },
    },
    simpleFieldsHeaders() {
      return this.headers.filter(el => el?.simpleField)
    },
  },

  filters: {
    truncate(str = '') {
      return str?.length && str.length > DEFAULT_TRUNCATE_LEN_SOURCES
        ? str.substring(0, DEFAULT_TRUNCATE_LEN_SOURCES) + '...'
        : str
    },
  },
  methods: {
    getRule(field) {
      return this?.[field] || []
    },
    getDeafaultAge(id) {
      if (!id) return ''

      return this.ageCategories.find(el => el.id == id)?.title || '-'
    },
    save(item, field, rules = []) {
      if (this.validate(this.editModel[field], rules)) {
        this.$set(item, field, this.editModel[field])
      }
    },
    validate(value, rules) {
      let result = true
      for (const rule of rules) {
        result = rule(value)
        if (typeof result === 'boolean' && result) {
          continue
        } else {
          result = false
          break
        }
      }

      return result
    },

    getDateForPicker(date) {
      if (!date) return ''

      return date.split('.').reverse().join('-')
    },
    setDateForPicker(date) {
      if (!date) return ''

      return date.split('-').reverse().join('.')
    },

    deleteSourceConfirm(item) {
      if (!item) return

      this.$emit('deleteSource', item)
    },

    createSource() {
      const date = this.setDateForPicker(new DateForPicker().toISO8601date)
      this.$emit('createSource', { ...DEFAULT_SOURCE, date })
      this.goToEndPage()
    },

    goToEndPage() {
      if (this.itemsPerPage < 0) return

      const np = this.sources?.length % this.itemsPerPage > 0
      this.page =
        Math.trunc(this.sources?.length / this.itemsPerPage) + (np ? 1 : 0)
    },
  },
}
</script>

<style scoped>
.v-data-table >>> td {
  padding: 0 8px !important;
}
.v-data-table >>> th {
  padding: 0 8px !important;
}
.v-data-table >>> tr .actions {
  display: none !important;
}
.v-data-table >>> tr:hover .actions {
  display: flex !important;
}
</style>
