// зачем эта бесполезная - ненадёжная функция с ручным разбором???))
export function getTimestamp(date) {
  const d = new Date(date)
  const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
  const yyyy = d.getFullYear()
  const MM = addZero(d.getMonth() + 1)
  const dd = addZero(d.getDate())
  return `${dd}.${MM}.${yyyy} г.`
}

/** полная дата время */
export function fullDateTime(timestamp) {
  const d = timestamp ? new Date(timestamp) : new Date()
  return d.toLocaleString()
}

/** таимштамп для даты YYYYMMDD */
export function dateStamp(date) {
  const d = date ? new Date(date) : new Date()
  const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
  const yyyy = d.getFullYear()
  const MM = addZero(d.getMonth() + 1)
  const dd = addZero(d.getDate())
  return `${yyyy}${MM}${dd}`
}

/** Вернём ближайшее время или дату если время дальше чем сегодня */
export function smartDateOrTime(timestamp) {
  const d = timestamp ? new Date(timestamp) : new Date()

  if (d.toDateString() === new Date().toDateString()) {
    // сегодня
    return d.toLocaleTimeString()
  } else {
    // вчера / завтра
    return d.toLocaleDateString()
  }
}

/** полная дата время */
export function smartIsToday(timestamp) {
  const d = timestamp ? new Date(timestamp) : new Date()
  return d.toDateString() === new Date().toDateString()
}
