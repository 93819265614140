import api from '../api'

const state = {
  // Список Источников
  storedDefaultSources: null,
  // Список возрастных категорий
  storedAgeCategories: null,
  // Клинические рекомендаций
  storedClinrecs: null,
  // счётчик блокировки загрузки
  loading: 0,
}

const getters = {
  // источники
  storedDefaultSources: state => state.storedDefaultSources,
  storedSources: (_, getters) => getters.storedFilter?.sources,
  // возрастные категории
  storedAgeCategoriesDict: state => state.storedAgeCategories,
  //клинические рекомендаций
  storedClinrecs: state => state.storedClinrecs,
  // справочникики в загрузке
  referencesLoading: state => !!state.loading,
}

const mutations = {
  SET_REFERENCES_LOADING(state, st = true) {
    state.loading += st ? 1 : -1
  },
  SET_DEFAULT_SOURCES(state, sources) {
    state.storedDefaultSources = sources
  },
  SET_CLINRECS(state, sources) {
    state.storedClinrecs = sources
  },
  SET_AGE_CATEGORIES(state, categoryes) {
    state.storedAgeCategories = categoryes
  },
}

const actions = {
  /** получаем доступные варианты источников ( по умолчанию ) */
  async GET_DEFAULT_SOURCES({ getters, commit }) {
    if (getters.storedDefaultSources) return
    try {
      commit('SET_REFERENCES_LOADING')
      const data = await api.getDefaultSources()
      commit('SET_DEFAULT_SOURCES', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_DEFAULT_SOURCES',
          text: 'Ошибка загрузки справочника типов/вариантов источников',
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_REFERENCES_LOADING', false)
    }
  },

  /** получаем доступные варианты возрастных категорий */
  async GET_AGE_CATEGORIES({ getters, commit }) {
    if (getters.storedAgeCategoriesDict) return
    try {
      commit('SET_REFERENCES_LOADING')
      const data = await api.getAgeCategories()
      commit('SET_AGE_CATEGORIES', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_AGE_CATEGORIES',
          text: 'Ошибка загрузки справочника возрастных категорий',
          err,
        },
        { root: true }
      )
      throw err
    } finally {
      commit('SET_REFERENCES_LOADING', false)
    }
  },
  /** получаем список клинических рекомендаций */
  async GET_CLINRECS({ getters, commit }) {
    if (getters.storedClinrecs) return
    try {
      const data = await api.getClinrecs()
      commit('SET_CLINRECS', data)
    } catch (err) {
      commit(
        'SET_ERROR',
        {
          head: 'GET_CLINRECS',
          text: 'Ошибка загрузки справочника клинических рекомендаций',
          err,
        },
        { root: true }
      )
      throw err
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
