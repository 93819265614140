<template>
  <div id="projects-page">
    <PageTitle title="Список фильтров" icon />
    <FiltersList />
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle.vue'
import FiltersList from '../entity/filters/FiltersList.vue'

export default {
  name: 'FiltersView',
  components: { FiltersList, PageTitle },
  computed: {},
}
</script>
