<template>
  <v-dialog fullscreen :value="true" persistent>
    <v-card>
      <v-card-title>
        Выбрать МКБ коды
        <v-spacer />
        <v-btn icon @click="closeMkbTree">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <MKBTable
          show-result
          :single-select="singleSelect"
          :value="codesString"
          @input="getSelectedCodes"
          @isValid="setValid"
          @isReady="setReady"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mr-5"
          color="primary"
          :disabled="isDisabledBtn"
          @click="confirmCodes"
          >Подтвердить выбор</v-btn
        >
        <v-btn @click="closeMkbTree">Отменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MKBTable from '@/modules/refs/entity/mkb10/MKBTable'

export default {
  name: 'MkbTreeSelectDialog',
  components: { MKBTable },
  props: {
    // входная строка с кодами для простановки галок выбора в дереве
    codesString: {
      type: String,
      default: '',
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    isNewCodes: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: true,
      isReady: false,
      selectedCodes: '',
      selectedCodesWithNames: '',
    }
  },
  computed: {
    // если мы ничего не изменили, то кнопку подтвердить блокируем
    changed() {
      return this.selectedCodes !== this.codesString
    },
    isDisabledBtn() {
      return (
        this.isReadOnly ||
        !this.isReady ||
        (this.isNewCodes && !this.selectedCodesWithNames && !this.isValid) ||
        (!this.isNewCodes && !this.changed)
      )
    },
  },
  created() {
    this.init()
  },
  watch: {
    codesString: 'init',
  },
  methods: {
    init() {
      this.selectedCodes = this.codesString
      this.selectedCodesWithNames = ''
    },
    // закрывает дерево без выбора
    closeMkbTree() {
      this.$emit('close-mkb-tree')
    },
    // закрывает дерево, выбрав коды, эмитит коды + коды с расшифровкой
    confirmCodes() {
      this.$emit(
        'confirm-selection',
        this.selectedCodes,
        this.selectedCodesWithNames
      )
    },
    // принимает эмит из дерева, 2 параметра - строку с кодами и список выбранных объектов
    getSelectedCodes(sortedLine, selectedItems) {
      this.selectedCodes = sortedLine
      this.selectedCodesWithNames = this.getItemsWithNames(selectedItems)
    },
    // собирает из списка выбранных объектов строку с коды+расшифровка, чисто для отображения в форме
    getItemsWithNames(selectedItems = []) {
      return selectedItems
        .sort((a, b) => a.code?.localeCompare(b.code))
        .reduce((result, item) => `${result}\n${item.code} ${item.name}`, '')
        .trim()
    },
    // принимает эмит из валидации входящих кодов, учитываем только при создании новых
    setValid(val) {
      if (this.isNewCodes) this.isValid = val
    },
    // принимает эммит из дерева, что оно загружено
    setReady(val) {
      this.isReady = val
    },
  },
}
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
  height: 250px;
  overflow-y: scroll;
}
</style>
