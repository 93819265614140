<template>
  <v-flex>
    <v-btn
      v-if="closeLoading"
      @click="abort.abort()"
      icon
      small
      title="Отменить загрузку"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <span>
      <v-menu bottom left open-on-hover offset-y rounded="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            title="Выгрузить список фильтров"
            text
            small
            class="mr-0 pr-2"
            :disabled="loading"
            :loading="loading"
          >
            Выгрузить
            <v-icon right>mdi-file-export-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item link v-if="isAdmin" @click="downloadJson">
            <v-list-item-icon>
              <v-icon color="orange">mdi-code-json</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              title="Выгрузить список фильтров в формате json"
              class="text-left"
            >
              Файл jSON
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="exportExcel">
            <v-list-item-icon>
              <v-icon color="success">mdi-file-excel-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              title="Выгрузить список фильтров в файл Excel"
              class="text-left"
            >
              Список критериев (.xlsx)
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="exportExcel({ withMKB10: true })">
            <v-list-item-icon>
              <v-icon size="24" color="success">mdi-file-excel</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="text-left"
              title="Выгрузить список фильтров в файл Excel, в разрезе кодов
              МКБ10"
            >
              Список критериев в разрезе кодов МКБ10 (.xlsx)
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canCoverageFilters"
            @click="exportExcelCoverage"
            link
          >
            <v-list-item-icon>
              <v-icon size="24" color="primary">mdi-file-excel-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="text-left"
              title="Выгрузить отчёт покрытия МКБ/КР/СМП"
            >
              Отчёт покрытия МКБ/КР/СМП (.xlsx)
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canCoverageFilters"
            @click="exportExcelCoverage({ withMKB10: true })"
            link
          >
            <v-list-item-icon>
              <v-icon size="24" color="primary">mdi-file-excel</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="text-left"
              title="Выгрузить отчёт покрытия МКБ/КР/СМП в разрезе кодов МКБ10"
            >
              Отчёт покрытия МКБ/КР/СМП в разрезе кодов МКБ10 (.xlsx)
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
  </v-flex>
</template>

<script>
import orphanModule from '../../../store'
import { mapGetters, mapMutations } from 'vuex'
import ExportToExcel from '@/services/export/excel/excel'
import { dateStamp } from '@/lib/date'
import FDLF from '../../../config/filterDataListFields'
import exportConfig from '../../../config/export/filtersToExcel'
import {
  DEFAULT_EMPTY_SOURCES_TXT,
  DEFAULT_EMPTY_AGE_TXT,
} from '../../../config/constants'
import api, { EApiCanceledException } from '@/lib/axiosApi'

export default {
  name: 'DownloadResource',
  data: () => ({
    loadingJson: false,
    loadingExcel: false,
    loadingCoverage: false,
    closeLoading: false,
    timer: 0,
    abort: api.createAbortController(),
  }),
  props: {
    ids: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...orphanModule.mapGetters([
      'storedFilterId',
      'storedFilterName',
      'storedSources',
      'filterDataList',
      'storedAgeCategoriesDict',
      'canCoverageFilters',
    ]),
    loading() {
      return this.loadingExcel || this.loadingJson || this.loadingCoverage
    },
  },
  watch: {
    loadingCoverage(val) {
      if (val) {
        this.timer = setTimeout(() => (this.closeLoading = true), 2000)
      } else {
        this.closeLoading = false
        this.abort = api.createAbortController()
        clearTimeout(this.timer)
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    if (this.loadingCoverage) this.abort.abort()
  },
  methods: {
    ...orphanModule.mapActions(['GET_FILTER_DATA_LIST', 'DOWNLOAD_FILE']),
    ...mapMutations(['SET_ERROR']),

    async updateData() {
      const id = this.storedFilterId
      await this.GET_FILTER_DATA_LIST({
        id,
        no_def: true,
        extra: true,
      })
    },

    async downloadJson() {
      this.loadingJson = true
      let element = document.createElement('a')
      const fileName = `filter_${this.storedFilterId}_data_${dateStamp()}.json`
      try {
        await this.updateData()

        let text = JSON.stringify(this.filterDataList)

        element.setAttribute(
          'href',
          'data:application/json;charset=utf-8,' + encodeURIComponent(text)
        )
        element.setAttribute('download', fileName)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()
      } catch (e) {
        this.SET_ERROR({
          head: 'Экспорт данных в Json',
          text: fileName,
          e,
        })
      } finally {
        this.loadingJson = false
        document.body.removeChild(element)
      }
    },
    async exportExcelCoverage({ withMKB10 = false }) {
      this.loadingCoverage = true
      const fileName = `отчёт покрытия МКБ/КР/СМП${
        withMKB10 ? ' в разрезе кодов МКБ10' : ''
      }`
      let url = `/orphan/filter-data/coverage-report/${this.storedFilterId}/xlsx`

      if (withMKB10) url += '?expand'

      try {
        await api.getFile(url, fileName, this.abort.signal)
      } catch (e) {
        if (e instanceof EApiCanceledException) {
          this.$toast.info('Загрузка файла отменена')
        } else {
          this.SET_ERROR({
            head: `Экспорт данных в Excel (отчёт покрытия)${
              withMKB10 ? ' в разрезе кодов МКБ10' : ''
            }`,
            text: fileName,
            e,
          })
        }
      } finally {
        this.loadingCoverage = false
      }
    },

    async exportExcel({ withMKB10 = false }) {
      this.loadingExcel = true
      const options = { ...exportConfig }

      options.fileName = `${this.storedFilterName || 'download'}${
        withMKB10 ? ' в разрезе кодов МКБ10_' : '_'
      }${dateStamp()}.xlsx`

      try {
        // обновить данные
        await this.updateData()

        // const rows = this.convertData(
        //   this.storedFilterName,
        //   this.filterDataList,
        //   withMKB10
        // )
        const rows = [
          {
            id: '1',
            data: this.convertData(
              this.storedFilterName,
              this.filterDataList,
              withMKB10
            ),
          },
        ]
        // Экспорт
        await ExportToExcel.downloadXLSX({ rows, options })
      } catch (e) {
        this.SET_ERROR({
          head: 'Экспорт данных в Excel',
          text: options.fileName,
          e,
        })
      } finally {
        this.loadingExcel = false
      }
    },

    convertData(listName, data, withMKB10) {
      const list = []
      let el = null
      if (this.ids?.length) {
        // Список был отфильтрован
        data = data.filter(el => this.ids.includes(el.id))
      }
      data.forEach((rows, index) => {
        const element = { index: index + 1 }
        for (const key in rows) {
          switch (key) {
            case FDLF.SOURCES_ID:
              el = this.getSourcesOnID(rows[key])

              element[`${FDLF.SOURCES}_short_name`] = el.short_name
              element[`${FDLF.SOURCES}_full_name`] = el.full_name
              element[`${FDLF.SOURCES}_date`] = el.date
              break
            // При окончательом выборе работы по source_id - убрать sources:
            // case FDLF.SOURCES:
            //   el = this.getSources(rows[key])

            //   element[`${key}_short_name`] = el.short_name
            //   element[`${key}_full_name`] = el.full_name
            //   element[`${key}_date`] = el.date
            //   break
            case FDLF.EXPERT_AGE_CATEGORY_ID:
              element[key] = this.getAgeNames(rows[key])
              break
            case FDLF.AGREED:
              element[key] = rows[key] ? 'Да' : 'Нет'
              break
            case FDLF.COMMENT:
              element[key] = rows[key] || ''
              break
            case FDLF.HAS_EXCEPT:
              element[key] = rows[key] ? 'Есть' : ''
              break
            default:
              element[key] = rows[key]
              break
          }
        }
        element.group = listName

        // Разрез в кодах МКБ10
        if (withMKB10) {
          let tmpElement = null
          // Валидные коды
          if (rows?.[FDLF.MKB10_CODES]?.length) {
            rows[FDLF.MKB10_CODES].forEach(el => {
              tmpElement = { ...element, [FDLF.EXPERT_MKB10_CODES]: el }
              list.push(tmpElement)
            })
          }
          // Не валидные коды
          if (rows?.[FDLF.MKB10_ERROR_CODES]?.length) {
            rows[FDLF.MKB10_ERROR_CODES].forEach(el => {
              tmpElement = {
                ...element,
                [FDLF.EXPERT_MKB10_CODES]: el,
                error: true,
              }
              list.push(tmpElement)
            })
          }
        } else {
          list.push(element)
        }
      })

      return list
    },

    getSources(data) {
      const res = {
        short_name: DEFAULT_EMPTY_SOURCES_TXT,
        full_name: '',
        date: '',
      }
      if (Array.isArray(data)) {
        if (!data?.length) return res

        res.short_name = data.map(el => el.short_name).join('; ')
        res.full_name = data.map(el => el.full_name || el.short_name).join('; ')
        res.date = data.map(el => el.date).join('; ')
      } else {
        res.short_name = data?.short_name || DEFAULT_EMPTY_SOURCES_TXT
        res.full_name = data?.full_name || res.short_name
        res.date = data?.date
      }

      return res
    },

    getSourcesOnID(id) {
      let res = {
        short_name: DEFAULT_EMPTY_SOURCES_TXT,
        full_name: '',
        date: '',
      }

      if (id && this.storedSources?.length) {
        const el = this.storedSources.find(el => el.id == id)
        if (el) {
          const {
            short_name = DEFAULT_EMPTY_SOURCES_TXT,
            full_name = '',
            date = '',
          } = el
          res = { short_name, full_name, date }
        }
      }

      return res
    },

    getAgeNames(id = 0) {
      if (!this.storedAgeCategoriesDict?.length) return ''
      if (!id) return DEFAULT_EMPTY_AGE_TXT

      return (
        this.storedAgeCategoriesDict.find(el => el.id == id).title ||
        DEFAULT_EMPTY_AGE_TXT
      )
    },
  },
}
</script>
