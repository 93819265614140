<template>
  <v-dialog
    v-model="dialogState"
    persistent
    width="1200px"
    :scrollable="!mobile"
    :fullscreen="mobile"
    @click:outside="closeOutside"
  >
    <!-- карточка диалога уничтожается когда спрятан -->
    <v-card v-if="dialogStateKiller" class="overflow-hidden">
      <v-toolbar
        flat
        :color="titleColor"
        extension-height="0px"
        dark
        class="dlg-header"
      >
        <v-icon class="mr-2">mdi-compare</v-icon>
        <v-toolbar-title class="font-weight-light">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn :disabled="!canClose" color="white" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template #extension>
          <!-- полоса загрузки -->
          <v-progress-linear
            :active="loadingMode"
            :color="progressColor"
            style="top: -2px"
            height="4px"
            indeterminate
          />
        </template>
      </v-toolbar>

      <!-- тело диалога -->
      <v-card-text class="text-body-1">
        <filter-filling-vault
          :value="filterDataValues"
          :valid.sync="valid"
          :readonly="readonly"
          :disabled="disabledForm"
          ref="FilterFillingVault"
          hide-agreed-status
          @change="onChange"
        />
        <v-divider />
        <v-alert
          v-if="save_error_message"
          type="error"
          outlined
          dense
          class="mt-2"
        >
          {{ save_error_message }}
        </v-alert>
      </v-card-text>
      <!-- кнопки  -->
      <v-card-actions class="flex-wrap">
        <v-checkbox
          :input-value="filterDataAgreed"
          :disabled="!canAgreed || saving"
          label="Согласовано"
          color="success"
          class="mb-5 ml-5"
          hide-details
          @change="agreedClick"
        />
        <v-spacer />
        <div class="d-flex">
          <div v-if="id" class="d-flex align-center">
            <span v-if="hasExcept" class="warning--text">Есть исключения</span>
            <span v-else class="text--secondary">Исключения отсутствуют</span>
          </div>
          <v-btn
            v-if="hasExcept || canEditExcept"
            @click="showExceptDialog = !showExceptDialog"
            :disabled="loadingMode"
            icon
            class="ml-2"
          >
            <v-icon v-if="canEditExcept">mdi-pencil</v-icon>
            <v-icon v-else>mdi-eye</v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <v-btn
          v-if="editMode"
          :disabled="!canSave"
          :loading="saving"
          color="primary"
          class="mr-2"
          text
          @click="saveClick"
        >
          Сохранить
        </v-btn>
        <!-- Закрыть / отменить -->
        <v-btn :disabled="!canClose" class="mr-2" text @click="closeDialog()">
          <!-- {{ editMode ? 'Отменить' : 'Закрыть' }} -->
          Закрыть
        </v-btn>
      </v-card-actions>
      <UnSavedAlertDialog
        v-model="dialogUnSaveAlert"
        :valid="valid"
        @onAction="saveClick()"
        @onSecondAction="closeDialog(true)"
      />
      <ExceptEditDialog
        v-if="showExceptDialog"
        :value="filterDataValues"
        :readonly="canReadonlyExcept || (canEditExcept && !edited)"
        :edit-mode="canEditExcept && edited"
        @close="showExceptDialog = false"
        @confirm="confirmExcept"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import orphanModule from '../../../../store'
import { sameObject } from '@/lib/objects'
import { debounce } from '@/lib/helpers'
import FilterFillingVault from './FilterFillingVault.vue'
import UnSavedAlertDialog from './dialogs/UnSavedAlertDialog.vue'
import ExceptEditDialog from '@/modules/orphan/entity/filter/forms/content/dialogs/ExceptEditDialog.vue'

export default {
  components: {
    ExceptEditDialog,
    FilterFillingVault,
    UnSavedAlertDialog,
  },
  name: 'FilterFillingVaultDlg',
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    dialogUnSaveAlert: false,
    showExceptDialog: false,
    killTimer: 0,
    saving: false,
    save_error_message: '',
    // данные после редактирования
    data: null,
    valid: true,
  }),
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    // Входящий ID критерия фильтра
    id: {
      type: Number || null,
      default: null,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    confirmClose: {
      type: Boolean,
      default: false,
    },
    edited: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...orphanModule.mapGetters([
      'filterDataValues',
      'filterDataСanAgreed',
      'filterDataAgreed',
      'loadingFilterDataValues',
      'canReadExceptFilters',
      'canEditExceptFilters',
    ]),
    canReadonlyExcept() {
      return this.canReadExceptFilters && !this.canEditExcept && this.hasExcept
    },
    canEditExcept() {
      return this.canEditExceptFilters && this.editMode && this.id
    },
    hasExcept() {
      return this.filterDataValues?.hasExcept || false
    },
    title() {
      return `${
        this.editMode
          ? this.appendMode
            ? 'Создание'
            : 'Редактирование'
          : 'Просмотр'
      } критерия фильтра`
    },
    titleColor() {
      return this.editMode ? 'teal' : 'blue-grey'
    },
    progressColor() {
      return this.editMode ? 'blue-grey lighten-4' : 'teal lighten-4'
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    disabledForm() {
      return this.loadingMode || this.savingMode
    },
    editMode() {
      return !this.readonly
    },
    appendMode() {
      return !this.id
    },
    loadingMode() {
      return !!this.loadingFilterDataValues
    },
    savingMode() {
      return !!this.saving
    },
    canSave() {
      return (
        !this.loadingMode &&
        !this.savingMode &&
        this.hasChanged /* && this.valid */
      )
    },
    canClose() {
      return !this.savingMode && !this.loadingMode
    },
    canCloseIfValid() {
      return !this.editMode || (this.editMode && this.valid)
    },
    canAgreed() {
      // разрешаем апрувить только существующие не свои критерии (user_creator_id)
      return (
        this.editMode &&
        this.filterDataСanAgreed &&
        this.valid &&
        !this.hasChanged
      )
    },
    // проверка было ли изменения
    hasChanged() {
      return this.editMode && !sameObject(this.filterDataValues, this.data)
    },
  },
  watch: {
    dialog(val) {
      this.dialogState = val
    },
    dialogState(value) {
      this.changeState(value)
      if (value) {
        // убрать таймер
        clearTimeout(this.killTimer)
        // если вдруг ещё была видна карточка убить и пересоздать на следующем такте
        this.dialogStateKiller = false
        this.$nextTick(() => (this.dialogStateKiller = true))
        // показали диалог, инициализация
        this.init()
      } // иначе, если диалог скрывает убиваем карточку через 0.5с (для анимации)
      else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 500)
    },
  },
  mounted() {
    this.doValidate = debounce(function () {
      this.$refs?.FilterFillingVault?.doValidate()
      this.saving = false
    }, 250)
  },

  beforeDestroy() {
    clearTimeout(this.killTimer)
    this.changeState(false)
  },
  methods: {
    ...orphanModule.mapActions([
      'CHANGE_FILTER_STATUS',
      'GET_FILTER_VALUES',
      'CREATE_FILTER_VALUES',
      'UPDATE_FILTER_VALUES',
      'CREATE_FILTER_EXCEPT',
    ]),
    closeDialog(force = false) {
      // Если были изменения и это не принудительное закрытие,
      if (!force && this.hasChanged) {
        // вызвать диалог подверждения закрытия
        this.dialogUnSaveAlert = true
      } else {
        this.$emit('onClose')
        this.dialogState = false
      }
    },
    closeOutside() {
      if (!this.editMode && this.canClose && this.canCloseIfValid) {
        this.closeDialog()
      } // иначе только через кнопку закрыть
    },
    changeState(state = false) {
      this.$emit('change', state)
      this.$emit('update:dialog', state)
    },
    ////////////////////
    init() {
      this.data = null
      this.GET_FILTER_VALUES(this.id).then(
        () => (this.data = this.filterDataValues)
      )
    },
    async agreedClick(check) {
      this.saving = true
      try {
        // Утверждаем отдельно из диалога
        await this.CHANGE_FILTER_STATUS(check)
        this.init() //reInit
      } catch (err) {
        this.save_error_message = err
      } finally {
        this.saving = false
      }
    },
    onChange(filterData) {
      this.data = filterData
    },
    async saveClick() {
      this.saving = true
      if (!this.valid) {
        this.doValidate()

        return
      }
      this.save_error_message = ''
      try {
        const id = this.id
        if (this.appendMode) {
          await this.CREATE_FILTER_VALUES(this.data)
        } else {
          await this.UPDATE_FILTER_VALUES({ id, ...this.data })
        }

        this.$toast.success(
          `"${
            this.data?.expert_desease || this.data?.source_desease || ''
          }" - Сохранено успешно.`
        )
        this.$emit('onAction')
        this.closeDialog(true)
      } catch (err) {
        this.save_error_message = err
      } finally {
        this.saving = false
      }
    },
    async confirmExcept() {
      this.showExceptDialog = false
      this.$toast.success('Исключения обновлены успешно!')
    },
  },
}
</script>

<style>
.dlg-header .v-toolbar__extension {
  padding: 0px 0px !important;
}
</style>
