import FDLF from '../filterDataListFields'

const smallWidth = 15
const mediumWidth = 20
const largeWidth = 30

const exportConfig = {
  worksheets: [
    {
      id: '1',
      name: 'Лист 1',
      columns: [
        // { header: 'Index', key: 'Index', width: smallWidth },
        {
          header: 'Группа заболеваний',
          key: 'group',
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ffd966' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Источник (полное наименование)',
          key: `${FDLF.SOURCES}_full_name`,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Источник (краткое наименование)',
          key: `${FDLF.SOURCES}_short_name`,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        // {
        //   header: 'Year',
        //   key: 'sources_date',
        //   width: smallWidth,
        //   style: { numFmt: '0000' },
        //   fill: {
        //     fgColor: { argb: 'c6e0b4' },
        //     pattern: 'solid',
        //     type: 'pattern',
        //   },
        // },
        {
          header: 'Дата',
          key: `${FDLF.SOURCES}_date`,
          width: smallWidth,
          fill: {
            fgColor: { argb: 'c6e0b4' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Заболевание/группа по МКБ-10 (из источника)',
          key: FDLF.SOURCE_DESEASE,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Возраст (из источника)',
          key: FDLF.SOURCE_AGE_CATEGORY,
          width: largeWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Kод по MKБ-10 (из источника)',
          key: FDLF.SOURCE_MKB10_CODES,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddebf7' },
            pattern: 'solid',
            type: 'pattern',
          },
        },
        {
          header: 'Заболевание (экспертное наименование)',
          key: FDLF.EXPERT_DESEASE,
          width: largeWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddeba7' },
            pattern: 'solid',
            type: 'pattern',
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
        {
          header: 'Возраст для заболевания (экспертное наименование)',
          key: FDLF.EXPERT_AGE_CATEGORY_ID,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddeba7' },
            pattern: 'solid',
            type: 'pattern',
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
        {
          header: 'Kод по MKБ-10 (из справочника)',
          key: FDLF.EXPERT_MKB10_CODES,
          width: mediumWidth,
          style: { alignment: { wrapText: true } },
          fill: {
            fgColor: { argb: 'ddeba7' },
            pattern: 'solid',
            type: 'pattern',
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
        {
          header: 'Согласовано',
          key: FDLF.AGREED,
          width: smallWidth,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: false,
            },
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
        {
          header: 'Комментарий',
          key: FDLF.COMMENT,
          width: largeWidth,
          style: {
            alignment: {
              vertical: 'middle',
              wrapText: false,
            },
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
        {
          header: 'Исключения',
          key: FDLF.HAS_EXCEPT,
          width: smallWidth,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: false,
            },
          },
          font: {
            color: { argb: 'f80a0a' },
          },
        },
      ],
      firstRow: {
        font: {
          name: 'Calibri',
          family: 4,
          size: 10,
          bold: true,
          color: { argb: '222222' },
        },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        height: 64,
      },
      views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
      autoFilter: { from: 'A1', to: 'M1' },
    },
  ],
}

export default exportConfig
