<template>
  <v-dialog width="1000px" :value="true" persistent>
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        Список исключений КР
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="actualHeaders"
          :items="exceptItems"
          group-by="caption"
          disable-pagination
          hide-default-footer
          disable-sort
          dense
        >
          <template #[`item.ext_id`]="{ item }">
            <v-text-field
              v-model="item.ext_id"
              readonly
              :title="item.id"
              class="mt-3"
              maxLength="3"
              :rules="[rules.required, rules.counter, rules.unique]"
              required
              persistent-hint
              dense
            ></v-text-field>
          </template>
          <template #[`item.reason`]="{ item }">
            <v-text-field
              v-model="item.reason"
              :readonly="readonly"
              :outlined="editMode"
              required
              class="mt-3"
              persistent-hint
              dense
            ></v-text-field>
          </template>
          <!--          <template v-slot:expanded-item="{ headers, item }">-->
          <!--            <td :colspan="headers.length" class="td-expanded">-->
          <!--              <span v-if="item.caption" class="text&#45;&#45;primary">{{-->
          <!--                item.caption-->
          <!--              }}</span>-->
          <!--              <span v-else class="warning&#45;&#45;text"-->
          <!--                >!!!КР по данному номеру не найден!!!</span-->
          <!--              >-->
          <!--            </td>-->
          <!--          </template>-->
          <template v-slot:[`group.header`]="{ headers, group }">
            <td :colspan="headers.length">
              <div v-if="group" class="font-weight-bold pa-2">
                {{ group }}
              </div>
              <div v-else>
                <span class="warning--text"
                  >!!!КР по данному номеру не найден!!!</span
                >
              </div>
            </td>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="actions">
              <btn-with-confirm
                v-if="editMode"
                @click="deleteExcept(item.idx)"
                class="mb-3"
                icon
                icon-main="mdi-delete-forever-outline"
                title="Удалить исключение"
              >
              </btn-with-confirm>
            </div>
          </template>
          <template #no-data v-if="!loading && !exceptItems.length">
            <v-alert
              v-if="!loading && !exceptItems.length"
              type="info"
              dense
              text
            >
              Исключения отсутствуют.
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="editMode" class="d-flex">
        <!--        <v-btn-->
        <!--          v-if="!loading && editMode"-->
        <!--          @click="showEdit = !showEdit"-->
        <!--          right-->
        <!--          fab-->
        <!--          small-->
        <!--          color="primary"-->
        <!--          title="Добавить исключение"-->
        <!--          class="mt-2 ml-3"-->
        <!--        >-->
        <!--          <v-icon v-if="showEdit"> mdi-minus </v-icon>-->
        <!--          <v-icon v-else> mdi-plus </v-icon>-->
        <!--        </v-btn>-->
        <v-autocomplete
          v-model="currentSearch"
          :items="storedClinrecs"
          :error-messages="notUnique ? errorMessage : ''"
          item-value="ext_id"
          item-text="caption"
          class="mx-4"
          dense
        >
          <template #append-outer>{{ currentSearch }}</template>
          <template #item="cr">
            <v-list-item ripple two-line>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span>
                    {{ cr.item.ext_id }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <span>{{ cr.item.caption }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
        <v-btn
          class="mb-3"
          :disabled="!currentSearch || notUnique"
          @click="addExcept"
          >Добавить</v-btn
        >
        <v-spacer />
      </v-card-actions>
      <v-card-actions class="d-flex justify-end" v-if="editMode">
        <v-btn
          :disabled="dataDifferent"
          text
          class="mr-5"
          color="primary"
          @click="confirm"
          >Сохранить</v-btn
        >
        <v-btn text @click="close">Закрыть</v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex justify-end" v-else-if="readonly">
        <v-btn text @click="close">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import orphanModule from '../../../../../store'
import btnWithConfirm from '@/components/ui/buttons/btnWithConfirm.vue'
import { copyObject, sameObject } from '@/lib/objects'
import { mapMutations } from 'vuex'

export default {
  name: 'ExceptEditDialog',
  components: { btnWithConfirm },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      exceptItems: [],
      loading: false,
      expanded: [],
      currentSearch: '',
      searchText: '',
      showEdit: false,
      mountedExceptItems: null,
      headers: [
        { text: 'код КР', value: 'ext_id', width: '150px' },
        { text: 'Причина исключения', value: 'reason' },
        { text: '', value: 'actions', width: '75px' },
      ],
      rules: {
        required: value => !!value || 'Обязательное поле.',
        counter: value => value.length === 3 || 'Трёхзначное id',
        unique: value =>
          this.exceptItems.filter(({ ext_id }) => ext_id == value).length ===
            1 || 'такое id уже существует',
      },
    }
  },
  computed: {
    ...orphanModule.mapGetters(['storedClinrecs']),
    actualHeaders() {
      return this.headers.filter(
        ({ value }) => value !== 'actions' || this.editMode
      )
    },
    dataDifferent() {
      return sameObject(this.exceptItems, this.mountedExceptItems)
    },
    errorMessage() {
      return `КР с данным id (${this.currentSearch}) уже существует`
    },
    notUnique() {
      return (
        this.exceptItems?.filter(
          ({ ext_id }) => Number(ext_id) === Number(this.currentSearch)
        ).length === 1
      )
    },
  },
  async created() {
    await this.init()
  },
  methods: {
    ...orphanModule.mapActions([
      'GET_FILTER_EXCEPT',
      'CREATE_FILTER_EXCEPT',
      'GET_CLINRECS',
    ]),
    ...orphanModule.mapMutations(['SET_EXCEPT_STATUS']),
    ...mapMutations(['SET_ERROR']),
    async getFilterExcept() {
      try {
        if (this.value.hasExcept) {
          this.exceptItems = copyObject(
            await this.GET_FILTER_EXCEPT(this.value.id)
          ).map((item, idx) => ({
            ...item,
            idx,
          }))
          this.mountedExceptItems = copyObject(this.exceptItems)
        }
      } catch (e) {
        this.SET_ERROR({
          head: 'Загрузка исключений',
          text: e,
          e,
        })
      }
    },
    async getCr() {
      try {
        this.GET_CLINRECS()
      } catch (e) {
        this.SET_ERROR({
          head: 'Загрузка КР',
          text: e,
          e,
        })
      }
    },
    async init() {
      this.loading = true
      try {
        await this.getFilterExcept()
        await this.getCr()
      } finally {
        this.loading = false
      }
    },
    deleteExcept(id) {
      this.exceptItems?.splice(
        this.exceptItems?.findIndex(({ idx }) => id === idx),
        1
      )
    },
    addExcept() {
      this.exceptItems.push({
        ext_id: this.currentSearch || '',
        reason: '',
        idx: this.exceptItems[this.exceptItems?.length - 1]?.idx + 1,
        caption:
          this.storedClinrecs?.find(
            ({ ext_id }) => ext_id === this.currentSearch
          )?.caption || '',
      })
      this.currentSearch = ''
      this.showEdit = false
    },
    // закрывает без выбора
    close() {
      this.$emit('close')
    },
    // закрывает изменив данные о исключениях
    async confirm() {
      this.loading = true
      try {
        const exceptItems = this.exceptItems.map(({ ext_id, reason }) => ({
          ext_id,
          reason,
        }))
        await this.CREATE_FILTER_EXCEPT({
          id: this.value.id,
          exceptList: exceptItems,
        })
        this.SET_EXCEPT_STATUS(!!exceptItems.length)
        this.$emit('confirm')
      } catch (e) {
        this.SET_ERROR({
          head: 'Обновление исключений',
          text: e,
          e,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
  height: 500px;
  overflow-y: scroll;
}
.v-data-table >>> tr .actions {
  display: none !important;
}
.v-data-table >>> tr:hover .actions {
  display: flex !important;
}

.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.v-data-table >>> .v-row-group__header {
  background: linear-gradient(to top, rgb(255, 255, 255) 1%, #dfdfdf 100%);
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
