<template>
  <v-form v-model="valid" :readonly="readonly" :disabled="disabled" ref="form">
    <v-container fluid v-bind="$attrs">
      <v-row v-if="showIsAgreed" dense>
        <v-col>
          <v-alert type="success" outlined dense class="mb-0" border="top"
            >Проверено и утверждено</v-alert
          >
        </v-col>
      </v-row>
      <!-- Блок Источник -->
      <v-row dense>
        <v-col class="pt-0">
          <!-- sourcesGroup -->
          <SourcesEditor
            v-model="internalData[FDLF.SOURCES]"
            :rules="sourcesGroupRules"
            :loading="referencesLoading"
            :items="storedSources"
            single
            color="secondary"
            @input="sourcesChange()"
          />
        </v-col>
      </v-row>
      <v-row align="center" class="mt-0 mb-0">
        <!-- Блок Источник -->
        <v-col cols="12" md="6">
          <v-card tile flat class="source_card pb-2">
            <v-card-title>
              <span class="h32"> Источник: </span>
              <v-spacer />
              <span class="h32" :style="hoverStyle" v-if="!readonly">
                <v-fade-transition group>
                  <v-btn
                    v-if="hoverSourceDesease"
                    key="copy"
                    color="orange"
                    fab
                    icon
                    x-small
                    title="Скопировать заболевание из источника в поле заболевания эксперной оценки"
                    @click="copySourceDeseaseClick"
                    @mouseover.native="hoverState(true)"
                    @mouseleave.native="hoverState(false)"
                  >
                    <v-icon small>mdi-transfer-right</v-icon>
                  </v-btn>
                </v-fade-transition>
              </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model.trim="internalData[FDLF.SOURCE_DESEASE]"
                item-text="Заболевание"
                label="Заболевание/группа по МКБ-10"
                class="mb-6"
                :clearable="editing"
                :rules="fieldRules"
                @input="onChange()"
                @blur="
                  hoverState(false)
                  onChange()
                "
                @mouseover.native="hoverState(true)"
                @mouseleave.native="hoverState(false)"
                @focus="hoverState(true)"
              />
              <v-flex ref="mkb10textfield">
                <v-textarea
                  v-model.trim="internalData[FDLF.SOURCE_MKB10_CODES]"
                  label="Коды по МКБ-10"
                  class="mb-6 pt-2"
                  no-resize
                  auto-grow
                  rows="1"
                  hint="Введите коды МКБ-10 из источника"
                  :clearable="editing"
                  :rules="fieldRules"
                  @input="onChange()"
                  @blur="onChange()"
                />
              </v-flex>
              <v-select
                v-model.trim="internalData[FDLF.SOURCE_AGE_CATEGORY]"
                label="Возрастная группа"
                :items="ageCatItems"
                :rules="ageCatRules"
                class="mt-2"
                @change="onChange()"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Блок Эксперт -->
        <v-col cols="12" md="6">
          <v-card tile flat class="expert_card pb-2">
            <v-card-title> Эксперт: </v-card-title>
            <v-card-text>
              <v-combobox
                v-model.trim="internalData[FDLF.EXPERT_DESEASE]"
                :search-input="internalData[FDLF.EXPERT_DESEASE]"
                label="Заболевание"
                hint="Заболевание (экспертное наименование)"
                :items="filterExpertDeseaseDict"
                :rules="fieldRules"
                class="mb-6"
                @change="onChange()"
                @blur="onChange()"
              />
              <v-flex ref="mkb10textarea">
                <v-textarea
                  v-model.trim="internalData[FDLF.EXPERT_MKB10_CODES]"
                  label="Коды МКБ-10 из Справочника"
                  readonly
                  class="mb-6 pt-2"
                  no-resize
                  auto-grow
                  rows="1"
                  append-outer-icon="mdi-store-search-outline"
                  :hint="mkb10textareaHint"
                  :rules="fieldRules"
                  :error-messages="expert_mkb10_codes_erorrs"
                  @click:append-outer="mkbTree = true"
                  @input="onChange()"
                  @blur="onChange()"
                />
              </v-flex>
              <v-select
                v-model="internalData[FDLF.EXPERT_AGE_CATEGORY_ID]"
                label="Возрастная группа"
                hint="Возраст для заболевания"
                :items="ageCatDict"
                item-text="title"
                item-value="id"
                :rules="ageCatRules"
                class="mt-2"
                @change="onChange()"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Блок комментарий -->
      <v-row class="mt-0">
        <v-col>
          <v-card flat class="comment_card">
            <v-card-title class="pb-0"> Комментарий: </v-card-title>
            <v-card-text>
              <v-textarea
                v-model.trim="internalData[FDLF.COMMENT]"
                title="Комментарий к критерию"
                :clearable="editing"
                auto-grow
                rows="3"
                dense
                @input="onChange()"
                @blur="onChange()"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- справочник мкб-10 -->
    <MkbTreeSelectDialog
      v-if="mkbTree"
      :codes-string="expert_mkb10_codes"
      :is-new-codes="!internalData[FDLF.EXPERT_MKB10_CODES]"
      :is-read-only="!editing"
      @close-mkb-tree="mkbTree = false"
      @confirm-selection="confirmCodes"
    />
  </v-form>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { lat_norm } from '@/lib/strUtils'
import orphanModule from '../../../../store'
import FDLF from '../../../../config/filterDataListFields'
import {
  CREATE_FDLF_OBJ,
  SOURCE_AGE_CATEGORY_DEFAULT,
} from '../../../../config/filterDataListFields'

import SourcesEditor from './editors/SourcesEditor.vue'
import MkbTreeSelectDialog from './dialogs/MkbTreeSelectDialog.vue'

export default {
  name: 'FilterFillingVault',
  inheritAttrs: false,
  components: { SourcesEditor, MkbTreeSelectDialog },
  props: {
    value: {
      type: Object || null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideAgreedStatus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: true,
    internalData: CREATE_FDLF_OBJ(),
    // правила валидации
    sourcesGroupRules: [
      v => Boolean(v?.id) || Boolean(v?.length) || 'Выберите источник критерия',
    ],
    fieldRules: [
      v => !!v || 'Поле должно быть заполнено',
      v => v?.length >= 3 || 'Поле должно содержать не менее 3х символов',
    ],
    ageCatRules: [v => !!v || 'Требуется указать возрастную группу'],
    // справочник МКБ
    mkbTree: false,
    FDLF,
    offHover: null,
    killTimer: null,
    hoverSourceDesease: false,
    startEditingNewCriteria: false,
  }),
  computed: {
    ...orphanModule.mapGetters([
      'referencesLoading',
      'storedSources',
      'storedAgeCategoriesDict',
      'filterExpertDeseaseDict',
    ]),
    editing() {
      return !this.readonly
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    ageCatDict() {
      return (
        (this.readonly
          ? [this.internalData?.[FDLF.EXPERT_AGE_CATEGORY]] // то что было
          : this.storedAgeCategoriesDict) || []
      )
    },
    ageCatItems() {
      return this.readonly
        ? [this.internalData?.[FDLF.SOURCE_AGE_CATEGORY]] // то что было
        : [SOURCE_AGE_CATEGORY_DEFAULT, ...this.ageCatDict?.map(el => el.title)]
    },
    mkb10textareaHint() {
      return this.mobile
        ? 'Выберете коды МКБ-10 из справочника'
        : 'Нажмите на кнопку поиска справа для выбора кодов МКБ-10 из справочника'
    },
    expert_mkb10_codes() {
      return (
        this.internalData?.[FDLF.EXPERT_MKB10_CODES] ||
        lat_norm(this.internalData?.[FDLF.SOURCE_MKB10_CODES]) ||
        ''
      )
    },
    expert_mkb10_codes_erorrs() {
      const end = this.mkb10_error_codes?.length > 1 ? 'ы' : ''
      return this.mkb10_error_codes
        ? `Код${end} ${this.mkb10_error_codes.join(
            ', '
          )} не найден${end} в справочнике`
        : null
    },
    mkb10_error_codes() {
      return this.internalData?.[FDLF.MKB10_ERROR_CODES] || null
    },

    outData() {
      return this.internalData
    },
    showIsAgreed() {
      return !this.hideAgreedStatus && this.internalData?.[FDLF.AGREED]
    },
    hoverStyle() {
      return this.mobile
        ? ''
        : {
            position: 'absolute',
            top: '78px',
            right: '-28px',
          }
    },
    expertDefaultAge() {
      return this.internalData[FDLF.SOURCES]?.default_age || null
    },
  },
  watch: {
    value: 'init',
    valid: 'onValidChange',
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
  },
  methods: {
    init() {
      this.internalData = copyObject(this.value) || CREATE_FDLF_OBJ()
      // сброс валидации для нового иначе валидируем
      const needValidate = !!this.value
      // Для нового критерия будет установка дефолтных значений возраста,
      // при выборе источника
      if (!needValidate && !this.readonly) {
        this.startEditingNewCriteria = true
      }

      this.$nextTick(() => {
        needValidate
          ? this.$refs?.form?.validate()
          : this.$refs?.form?.resetValidation()
      })
    },
    // эмит из диалога с деревом: нам приходит строка кодов + строка кодов с расшифровками
    confirmCodes(codes /*, codesWithNames */) {
      this.mkbTree = false
      // this.mkb10_with_name = codesWithNames
      this.$set(this.internalData, FDLF.EXPERT_MKB10_CODES, codes)
      // подчистили коды ошибок
      this.$set(this.internalData, FDLF.MKB10_ERROR_CODES, undefined)
      this.onChange()
    },
    sourcesChange() {
      const source_id = this.internalData[FDLF.SOURCES]?.id || null
      this.$set(this.internalData, FDLF.SOURCES_ID, source_id)

      // если это первый выбор то ставим возрастные группы по дефолту
      if (this.startEditingNewCriteria) {
        this.startEditingNewCriteria = false
        // Возраст источника
        this.$set(
          this.internalData,
          FDLF.SOURCE_AGE_CATEGORY,
          SOURCE_AGE_CATEGORY_DEFAULT
        )
        // возраст экспертный
        this.$set(
          this.internalData,
          FDLF.EXPERT_AGE_CATEGORY_ID,
          this.expertDefaultAge
        )
      }
      this.onChange()
    },
    onChange() {
      if (this.editing) {
        this.$emit('change', this.outData, this.valid)
      }
    },
    onValidChange(value) {
      this.$emit('update:valid', value)
    },
    doValidate() {
      this.$nextTick(() => {
        this.$refs?.form?.resetValidation()
      })
      setTimeout(() => {
        this.$refs?.form?.validate()
      }, 100)
    },
    hoverState(value) {
      if (value) {
        clearTimeout(this.killTimer)
        this.hoverSourceDesease = true
      } else
        this.killTimer = setTimeout(() => {
          this.hoverSourceDesease = false
        }, 1000)
    },
    copySourceDeseaseClick() {
      clearTimeout(this.killTimer)
      const desease = this.internalData[FDLF.SOURCE_DESEASE] || ''
      this.$set(this.internalData, FDLF.EXPERT_DESEASE, desease)

      this.onChange()
    },
  },
}
</script>

<style scoped>
.source_card {
  background-color: rgba(60, 179, 113, 0.05);
}
.expert_card {
  background-color: rgba(106, 90, 205, 0.05);
}
.comment_card {
  background-color: rgba(232, 162, 47, 0.05);
}
::v-deep .h32 {
  height: 32px;
}
</style>
