<template>
  <v-dialog v-model="dialogState" :width="width" v-bind="$attrs" persistent>
    <!-- прокидываем слот activator -->
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <!-- карточка диалога -->
    <v-card v-if="dialogStateKiller" class="overflow-hidden">
      <v-toolbar flat :color="toolBarColor" dark>
        <!-- шапка -->
        <v-icon class="mr-2">mdi-alert-outline</v-icon>
        <v-toolbar-title class="font-weight-light">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- заголовок - вопрос -->
      <v-card-title>
        <span style="hyphens: manual" :inner-html.prop="label" />
      </v-card-title>
      <!-- доп иформация через слот -->
      <v-card-text class="text-body-1">
        <slot name="text" />
      </v-card-text>
      <!-- кнопки  -->
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!hideActionBtn"
          text
          :color="colorBtnAction"
          @click="closeAndAction"
        >
          {{ labelBtnAction }}
        </v-btn>
        <v-btn
          v-if="showSecondBtn"
          text
          :color="colorBtnSecondAction"
          @click="closeAndSecondAction"
        >
          {{ labelBtnSecondAction }}
        </v-btn>
        <v-btn
          v-if="!hideCloseBtn"
          text
          :color="colorBtnClose"
          @click="closeDialog"
          >{{ labelBtnClose }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionDialog',
  inheritAttrs: false,
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    killTimer: 0,
  }),
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '660px',
    },
    toolBarColor: {
      type: String,
      default: 'blue-grey',
    },
    title: {
      type: String,
      default: 'Предупреждение системы',
    },
    label: {
      type: String,
      default: '',
    },
    hideActionBtn: {
      type: Boolean,
      default: false,
    },
    labelBtnAction: {
      type: String,
      default: 'ПРИМЕНИТЬ',
    },
    colorBtnAction: {
      type: String,
      default: 'primary',
    },
    showSecondBtn: {
      type: Boolean,
      default: false,
    },
    labelBtnSecondAction: {
      type: String,
      required: false,
      default: 'ПРОДОЛЖИТЬ',
    },
    colorBtnSecondAction: {
      type: String,
      required: false,
      default: 'secondary',
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
    labelBtnClose: {
      type: String,
      default: 'ОТМЕНА',
    },
    colorBtnClose: {
      type: String,
      default: undefined,
    },
  },

  watch: {
    dialog(val) {
      this.dialogState = val
    },
    dialogState() {
      this.changeState(this.dialogState)
      if (this.dialogState) {
        clearTimeout(this.killTimer)
        // если вдруг ещё была видна карточка убить и пересоздать на следующем такте
        this.dialogStateKiller = false
        this.$nextTick(() => (this.dialogStateKiller = true))
      } else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 1000)
    },
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
    this.changeState(false)
  },
  methods: {
    closeDialog() {
      this.$emit('onClose')
      this.dialogState = false
    },
    closeAndAction() {
      this.$emit('onAction')
      this.dialogState = false
    },
    closeAndSecondAction() {
      this.$emit('onSecondAction')
      this.dialogState = false
    },
    changeState(state) {
      this.$emit('change', state)
      this.$emit('update:dialog', state)
    },
  },
}
</script>
