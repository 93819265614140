import Vue from 'vue'
import api from '../api'
import refs from './refs'
import filterData from './filterData'

export default {
  namespaced: true,

  state: () => ({
    loadingFiltersList: false,
    loadingFilter: false,
    filtersList: [],
    filterId: null,
  }),

  getters: {
    // стутус Filter в загрузке
    loadingFiltersList: state => state.loadingFiltersList,
    loadingFilter: state => state.loadingFilter,
    // Текущий Filter
    storedFilterId: state => state.filterId,
    storedFilter: (_, getters) =>
      getters.storedFiltersList?.find(
        ({ id }) => id === getters.storedFilterId
      ) ?? null,
    storedFilterName: (_, getters) => getters.storedFilter?.name || '',
    storedFiltersList: state => state.filtersList || [],

    // Пользователь может создавать новые списки фильтров
    canCreateFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canCreate ||
      false,
    // Пользователь может просматривать фильтры
    canReadFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canRead ||
      false,
    // Пользователь может редактировать фильтры
    canEditFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canEdit ||
      false,
    // Пользователь может сохранять отчёт покрытия МКБ/КР/СМП
    canCoverageFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canCoverageReport ||
      false,
    canReadExceptFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canReadExcept ||
      false,
    canEditExceptFilters: (state, getters, rootState, rootGetters) =>
      rootGetters.isAdmin ||
      rootGetters.userAttributes?.filters?.canEditExcept ||
      false,
  },

  mutations: {
    SET_DEFAULT_FILTERS(state) {
      state.filtersList = []
      state.loadingFilters = false
      state.loadingFiltersList = false
      state.filterId = null
    },

    SET_LOADING_FILTERS(state, st) {
      state.loadingFilters = !!st
    },

    SET_FILTERS(state, filters) {
      state.filterId = null
      state.filtersList = filters
    },

    SET_DEFAULT_FILTER(state) {
      state.loadingFilter = false
      state.filterId = null
    },

    SET_LOADING_FILTER(state, st) {
      state.loadingFilter = !!st
    },

    SET_FILTER(state, filter) {
      // обновить фильтр в массиве
      const index =
        state.filtersList?.findIndex(({ id }) => id === filter.id) ?? -1
      if (index >= 0) {
        Vue.set(state.filtersList, index, filter)
      } else state.filtersList.push(filter)
    },
    REMOVE_FILTER(state, filter) {
      // убрать фильтр из массива
      const index =
        state.filtersList?.findIndex(({ id }) => id === filter.id) ?? -1
      if (index >= 0) {
        state.filtersList?.splice(index, 1)
      }
    },
    SET_ACTIVE_FILTER(state, id = null) {
      state.filterId = id ? Number(id) : null
    },
  },

  actions: {
    // загрузить все фильтры
    async GET_FILTERS({ commit, getters }) {
      if (getters.loadingFilter)
        throw new Error('Список фильтров находится на стадии загрузки!')
      try {
        commit('SET_LOADING_FILTERS', true)
        commit('SET_FILTERS', [])

        const data = await api.getFilters()

        commit('SET_FILTERS', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'GET_FILTERS',
            text: `Ошибка получения списка фильтров`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTERS', false)
      }
    },

    async GET_FILTER({ commit, getters }, id) {
      if (getters.storedFilterId === id) return getters.storedFilter

      try {
        commit('SET_LOADING_FILTER', true)
        // можно предварительно взять фильтр из массива если он уже там
        commit('SET_ACTIVE_FILTER', id)
        const data = await api.getFilter(id)

        commit('SET_FILTER', data)
        return data
      } catch (err) {
        commit('SET_DEFAULT_FILTERS')
        commit(
          'SET_ERROR',
          {
            head: 'GET_FILTER',
            text: `Ошибка получения фильтра id: ${id}`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER', false)
      }
    },

    async CREATE_FILTER({ commit }, filter) {
      try {
        const data = await api.createFilter(filter)
        commit('SET_FILTER', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'CREATE_FILTER',
            text: 'Ошибка создания нового фильтра',
            err,
          },
          { root: true }
        )
        throw err
      }
    },

    async EDIT_FILTER({ commit }, filter) {
      try {
        const data = await api.editFilter(filter)
        commit('SET_FILTER', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'EDIT_FILTER',
            text: `Ошибка обновления фильтра id: ${filter?.id}`,
            err,
          },
          { root: true }
        )
        throw err
      }
    },

    async DELETE_FILTER({ dispatch, commit }, filter) {
      try {
        await api.deleteFilter(filter)
        commit('REMOVE_FILTER', filter)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'DELETE_FILTER',
            text: `Ошибка удаления фильтра с id: ${filter?.id}`,
            err,
          },
          { root: true }
        )
        dispatch('GET_FILTERS')
        throw err
      }
    },
  },

  modules: {
    filterData,
    refs,
  },
}
