import api from '@/lib/axiosApi'

export const refs = {
  getDefaultSources: () => {
    return api.get('orphan/sources')
  },
  getAgeCategories: () => {
    return api.get('orphan/age-categories')
  },
  getClinrecs: () => {
    return api.get('orphan/clinrecs')
  },
}

export default refs
