<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    title="ВНИМАНИЕ!!!"
    label="Вы действительно хотите удалить источник ?"
    colorBtnClose="blue darken-1"
    labelBtnClose="ОТМЕНИТЬ"
    colorBtnAction="red darken-1"
    labelBtnAction="УДАЛИТЬ"
  >
    <!-- @onAction="deleteSourceConfirm" -->
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #text>
      <span class="font-weight-bold">"{{ sourceName }}"</span>
      <p v-if="sourceFullName" class="mt-2 text--disabled">
        {{ sourceFullName }}
      </p>
    </template>
  </ActionDialog>
</template>

<script>
import ActionDialog from '@/components/ui/dialogs/ActionDialog.vue'

export default {
  name: 'SourceDeleteDialog',
  components: { ActionDialog },
  data: () => ({
    deleting: false,
  }),
  props: {
    source: {
      type: Object,
      required: false,
    },
  },
  computed: {
    sourceName() {
      return this.source?.short_name || ''
    },
    sourceFullName() {
      return this.source?.full_name || ''
    },
  },
}
</script>
