<template>
  <v-dialog
    v-model="dialogState"
    width="1080px"
    persistent
    :scrollable="!mobile"
    :fullscreen="mobile"
  >
    <!-- :scrollable="!mobile" -->
    <!-- прокидываем все слоты -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card v-if="dialogStateKiller" :loading="loading" class="pb-1">
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="filterUuid" class="mb-0 pb-0 text--disabled">
        <span class="font-weight-bold">UID:</span>
        {{ filterUuid }}
      </v-card-subtitle>
      <v-card-text class="py-2">
        <v-form>
          <v-tabs height="32" v-model="tab">
            <v-tab href="#main">Общие</v-tab>
            <v-tab href="#sources">Источники</v-tab>
            <v-tab-item class="pt-4" value="main">
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="name"
                      label="Название фильтра"
                      placeholder="Название фильтра"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model.trim="description"
                      label="Дополнительная информация"
                      rows="1"
                      clearable
                      auto-grow
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="pt-2" value="sources">
              <FilterSourcesEditor
                v-model="sources"
                :ageCategories="ageCategories"
                @deleteSource="deleteSource"
                @createSource="createSource"
              />
            </v-tab-item>
          </v-tabs>
          <v-alert v-if="error_message" type="error" dense outlined>
            {{ error_message }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="primary"
          class="mr-4"
          text
          :loading="saving"
          :disabled="!name"
          @click="submitHandler"
        >
          Сохранить
        </v-btn>
        <v-btn @click="closeDialog" class="mr-4" text> Отменить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import orphanModule from '../../../store'
import { copyObject } from '@/lib/objects'
import { DEFAULT_EMPTY_AGE_TXT } from '../../../config/constants'

import FilterSourcesEditor from './components/FilterSourcesEditor.vue'

export default {
  name: 'FilterEditorDialog',
  components: { FilterSourcesEditor },
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    killTimer: 0,
    loading: false,
    saving: false,
    id: null,
    name: '',
    description: '',
    tab: 'main',
    sources: [],
    ageCategories: [],
    error_message: '',
  }),
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...orphanModule.mapGetters([
      'storedDefaultSources',
      'storedAgeCategoriesDict',
    ]),
    title() {
      return this.append ? 'Добавить новый фильтр' : `Редактировать фильтр`
    },
    action() {
      return this.append ? this.CREATE_FILTER : this.EDIT_FILTER
    },
    formData() {
      const { id, name, description, sources } = this
      return { id, name, description, sources }
    },
    filterId() {
      return this.filter?.id || null
    },
    filterUuid() {
      return this.filter?.uuid
    },
    mobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
  },
  watch: {
    dialog(val) {
      this.dialogState = val
    },
    dialogState() {
      this.changeState()
      if (this.dialogState) {
        clearTimeout(this.killTimer)
        this.dialogStateKiller = true
        this.init()
      } else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 1000)
    },
    filter: 'init',
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
  },
  methods: {
    ...orphanModule.mapActions([
      'GET_DEFAULT_SOURCES',
      'GET_AGE_CATEGORIES',
      'GET_FILTER',
      'CREATE_FILTER',
      'EDIT_FILTER',
    ]),
    closeDialog() {
      this.$emit('onClose')
      this.dialogState = false
    },
    closeAndAction() {
      this.$emit('onAction')
      this.dialogState = false
    },
    changeState() {
      this.$emit('change', this.dialogState)
    },

    async init() {
      this.error_message = ''
      this.tab = 'main'
      if (this.filterId && !this.append) {
        const data = await this.GET_FILTER(this.filterId)
        const {
          id = undefined,
          name = '',
          description = '',
          sources = [],
        } = data || {}
        this.id = id
        this.name = name
        this.description = description
        this.sources = copyObject(sources) || []
      } else {
        // Добавляем источники по умолчанию
        try {
          await this.GET_DEFAULT_SOURCES()
          this.sources = copyObject(this.storedDefaultSources)
        } catch (err) {
          this.error_message = err
        }
      }
      try {
        await this.GET_AGE_CATEGORIES()
        this.ageCategories = [
          { id: null, title: DEFAULT_EMPTY_AGE_TXT },
          ...copyObject(this.storedAgeCategoriesDict),
        ]
      } catch (err) {
        this.error_message = err
      }
    },

    async submitHandler() {
      this.error_message = ''
      this.saving = true
      try {
        await this.action(this.formData)
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.saving = false
      }
    },

    deleteSource(item) {
      if (!item) return

      const index = this.sources.indexOf(item)
      if (index !== -1) {
        this.sources.splice(index, 1)
        // this.$set(this, 'sources', sources)
      }
    },

    createSource(item) {
      if (!item) return
      this.sources.push(item)
    },
  },
}
</script>
