<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    title="ВНИМАНИЕ!!!"
    :label="criterionLabel"
    colorBtnClose="blue darken-1"
    colorBtnAction="orange darken-1"
    labelBtnClose="ОТМЕНИТЬ"
    labelBtnAction="СКОПИРОВАТЬ"
    @onAction="copyFilterCriterionConfirm"
  >
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #text>
      <span v-if="name" class="text-subtitle-1">{{ criterionName }}</span>
      <p v-if="criterionDescription" class="mt-2 text--disabled">
        Заболевание: "{{ criterionDescription }}"
      </p>
    </template>
  </ActionDialog>
</template>

<script>
import orphanModule from '../../../store'
import { copyObject } from '@/lib/objects'
import FDLF from '../../../config/filterDataListFields'
import ActionDialog from '@/components/ui/dialogs/ActionDialog.vue'

export default {
  name: 'FilterCriterionCopyDialog',
  components: { ActionDialog },
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    selected: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    deleting: false,
    criteria: {},
  }),

  mounted() {
    this.criteria = this.getCriteria(this.selected)
  },

  watch: {
    selected(val) {
      this.criteria = this.getCriteria(val)
    },
  },

  computed: {
    ...orphanModule.mapGetters(['filterDataList']),
    criterionName() {
      return `Фильтр: "${this.name || ''}"`
    },
    criterionDescription() {
      return (
        this.criteria?.[FDLF.SOURCE_DESEASE] ||
        this.criteria?.[FDLF.EXPERT_DESEASE] ||
        ''
      )
    },
    criterionLabel() {
      return `Скопировать критерий фильтра ?`
    },
  },
  methods: {
    ...orphanModule.mapActions(['CREATE_FILTER_VALUES']),
    async copyFilterCriterionConfirm() {
      if (this.selected?.length !== 1 || this.copyProcess) return

      const candidateCriteria = this.filterDataList.find(
        el => el.id == this.criteria.id
      )

      if (!candidateCriteria) return

      this.copyProcess = true

      const criteria = copyObject(candidateCriteria)
      delete criteria.id
      criteria[FDLF.SOURCE_DESEASE] = `Копия ${
        criteria?.[FDLF.SOURCE_DESEASE] || ''
      }`
      criteria[FDLF.SOURCES] = criteria?.[FDLF.SOURCES]?.length
        ? criteria[FDLF.SOURCES].map(el => el.id)
        : criteria[FDLF.SOURCES]

      try {
        await this.CREATE_FILTER_VALUES(criteria)
        this.$toast.success('Критерий фильтра скопирован успешно!')
      } catch (err) {
        console.log(err)
      } finally {
        this.copyProcess = false
        this.$emit('onClose')
      }
    },

    getCriteria(arr) {
      return arr?.length === 1 ? arr[0] : {}
    },
  },
}
</script>
