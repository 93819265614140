import ExcelJS from 'exceljs'

export class ExcelException extends Error {
  constructor(message) {
    super(message)
    this.name = 'ExcelException'
  }
}

export class ExportExcel {
  constructor({ rows = [], options = {} }) {
    this.rowsData = rows
    this.options = options
    this.workbook = null
    this.worksheet = []
    this.headerCells = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
    ]
  }

  init() {
    try {
      this.workbook = new ExcelJS.Workbook()
      // this.workbook.creator = this.options?.creator || 'Автор неизвестен'
      this.workbook.created = this.options?.created || new Date()
      this.workbook.modified = this.options?.modified || new Date()
      this.workbook.lastPrinted = this.options?.lastPrinted || new Date()

      const { worksheets = [] } = this.options
      worksheets.forEach((worksheet, index) => {
        this.worksheet[index] = this.workbook.addWorksheet(
          worksheet?.name || `Лист ${index + 1}`
        )

        this.worksheet[index].state = 'visible'
      })
    } catch (err) {
      throw new ExcelException(`Ошибка инициализации документа Excel  ${err}`)
    }
  }

  createView() {
    try {
      this.headerCells = this.headerCells.map(el => el + 1)

      const { worksheets = [] } = this.options

      worksheets.forEach((worksheet, index) => {
        // const real_worksheet = this.workbook.getWorksheet(
        //   this.worksheet[index].name
        // )
        const real_worksheet = this.worksheet[index]

        real_worksheet.views = worksheet.views || [{}]
        worksheet?.autoFilter &&
          (real_worksheet.autoFilter = worksheet?.autoFilter)
        worksheet?.columns && (real_worksheet.columns = worksheet.columns)
        // TODO
        // Не обязательно первой строкой может быть шапка таблицы на листе
        const firstRow = real_worksheet.getRow(1)
        firstRow.font = worksheet?.firstRow?.font || {}
        firstRow.alignment = worksheet?.firstRow?.alignment || {}
        firstRow.height = worksheet?.firstRow?.height || 20

        // Покраска заголовка таблицы
        if (worksheet?.columns?.length) {
          this.headerCells.map((key, i) => {
            if (worksheet?.columns[i]?.fill) {
              real_worksheet.getCell(key).fill = {
                ...real_worksheet.getCell(key).fill,
                ...worksheet.columns[i].fill,
              }
            }
            if (worksheet?.columns[i]?.font) {
              real_worksheet.getCell(key).font = {
                ...real_worksheet.getCell(key).font,
                ...worksheet.columns[i].font,
              }
            }
          })
        }
      })
    } catch (err) {
      throw new ExcelException(`Ошибка подготовки оформления таблицы ${err}`)
    }
  }

  createRows() {
    try {
      const { worksheets = [] } = this.options

      worksheets.forEach((worksheet, index) => {
        // const real_worksheet = this.workbook.getWorksheet(
        //   this.worksheet[index].name
        // )
        const rowsData =
          this.rowsData.find(srd => srd.id == worksheet.id)?.data || []
        for (const rowValues of rowsData) {
          let row = this.worksheet[index].addRow({
            ...rowValues,
          })
          rowValues?.error &&
            row.eachCell(cell => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFF0F5' },
                ...cell.fill,
              }
            })
        }
      })
    } catch (err) {
      throw new ExcelException(`Ошибка заполнения столбцов данными ${err}`)
    }
  }

  createAdditionalRows() {
    try {
      const { worksheets = [] } = this.options
      worksheets.forEach((worksheet, index) => {
        if (worksheet?.additionalRows) {
          const additionalRows = worksheet?.additionalRows
          const real_worksheet = this.worksheet[index]

          const columns = additionalRows?.columns || null
          if (columns?.length) {
            const headers = columns.map(c => c.header)
            real_worksheet.insertRow(real_worksheet.rowCount + 3, headers, 'n')
            const firstRow = real_worksheet.lastRow
            firstRow.font = additionalRows?.firstRow?.font || {}
            firstRow.alignment = additionalRows?.firstRow?.alignment || {}
            firstRow.height = additionalRows?.firstRow?.height || 20

            columns.forEach((c, colNumber) => {
              if (c?.fill) {
                firstRow.getCell(colNumber + 1).fill = {
                  ...firstRow.getCell(colNumber + 1).fill,
                  ...c.fill,
                }
              }
            })
          }

          const rows =
            this.rowsData.find(srd => srd.id == additionalRows.id)?.data || []
          // const bias = real_worksheet.rowCount
          rows.forEach(r => {
            // real_worksheet.insertRow(bias + index + 1, r, 'n')
            real_worksheet.addRow(r)
          })
        }
      })
    } catch (err) {
      throw new ExcelException(
        `Ошибка заполнения дополнительных столбцов данными ${err}`
      )
    }
  }

  async download() {
    try {
      const data = await this.workbook.xlsx.writeBuffer()

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = this.options?.fileName || 'download.xls'
      anchor.click()
      window.URL.revokeObjectURL(url)
    } catch (err) {
      throw new Error(
        `Ошибка выгрузки Excel: ${err.message} -> ${err.config.url}`
      )
    }
  }

  async process() {
    this.init()
    this.createView()
    this.createRows()
    this.createAdditionalRows()

    await this.download()
  }

  static async downloadXLSX(options) {
    const instance = new this(options)
    await instance.process()
  }
}

export default ExportExcel
