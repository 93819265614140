<template>
  <v-container class="pt-0" fluid>
    <v-row class="my-0" dense>
      <v-col cols="12" md="8">
        <span
          v-if="storedFilter?.description"
          class="text-body-2 pre-wrap text--secondary"
          >{{ storedFilter?.description }}</span
        >
      </v-col>
      <v-col class="text-right text-caption text--secondary" align-self="end">
        <span class="font-weight-medium mr-2">Дата изменения: </span
        ><span>{{ updateTime }}</span>
      </v-col>
    </v-row>
    <v-row class="my-0" dense>
      <v-col class="text-right">
        <DownloadResource :ids="filteredIds" />
      </v-col>
    </v-row>
    <v-row class="my-0" dense>
      <v-col cols="12" sm="5" md="3" class="pt-2" v-if="canEditFilters">
        <v-switch
          v-model="edited"
          :label="`Режим ${edited ? 'редактора' : 'просмотра'}`"
          hide-details
          dense
          inset
        />
      </v-col>
      <v-col cols="12" sm="7" md="6" offset-md="3" class="pt-0">
        <v-text-field
          class="custom-field"
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск по критериям"
          single-line
          clearable
          hide-details
          :disabled="loading || !itemsList?.length"
        />
      </v-col>
    </v-row>
    <v-row v-if="!error_message" class="mt-0">
      <v-col>
        <v-data-table
          v-model="selected"
          v-click-outside="onBlurDTable"
          :headers="headers"
          :items="itemsList"
          :search="search"
          :footerProps="table_footer_options"
          :loading="loading && !loadingFilterDataValues"
          :hide-default-footer="hideFooter"
          :show-select="edited"
          loading-text="Загружаются значения фильтра"
          no-results-text="Не найдены значения"
          single-expand
          dense
          :item-class="rowClass"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sortBy.sync="sortBy"
          @click:row="editFilterValues"
          @contextmenu:row="rightClickHandler"
        >
          <!-- ДОПОЛНИТЕЛЬНОЕ ОФРОМЛЕНИЕ ЗАГОЛОВКОВ ТАБЛИЦЫ -->
          <template #[`header.data-table-select`]="{ props }">
            <v-simple-checkbox
              v-if="selected?.length"
              v-bind="props"
              @click="selected = []"
            />
          </template>
          <template v-slot:header="{ isMobile }">
            <thead>
              <tr class="group-header">
                <th v-if="edited" colspan="2" class="pa-1">
                  <v-fade-transition group>
                    <!-- Обязательно нужен key для transition ! -->
                    <!-- computed selected?.length !== 1 не срабатывает -->
                    <span v-if="showActionsBtn" key="copy">
                      <FilterCriterionCopyDialog
                        :selected="selected"
                        :name="storedFilterName"
                        @onClose="onCloseActionDialog"
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="selected?.length !== 1"
                            color="orange"
                            fab
                            icon
                            x-small
                            title="Скопировать выбранный критерий"
                          >
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                      </FilterCriterionCopyDialog>
                    </span>
                    <span v-if="showActionsBtn" key="delete" class="ml-1">
                      <FilterCriterionDeleteDialog
                        :criteria="selected"
                        :name="storedFilterName"
                        @onClose="onCloseActionDialog"
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="pink"
                            dark
                            fab
                            icon
                            x-small
                            title="Удалить выбранные критерии"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                      </FilterCriterionDeleteDialog>
                    </span>
                  </v-fade-transition>
                </th>
                <th v-else></th>
                <th v-if="!isMobile" class="comment_card"></th>
                <th
                  v-if="!isMobile"
                  colspan="3"
                  class="text-subtitle-2 text-center source_card"
                >
                  СВЕДЕНИЯ ИЗ ИСТОЧНИКА
                  <hr />
                </th>
                <th
                  v-if="!isMobile"
                  colspan="3"
                  class="text-subtitle-2 text-center expert_card"
                >
                  ЭКСПЕРТНОЕ НАИМЕНОВАНИЕ
                  <hr />
                </th>
                <th colspan="2" class="px-1 text-right">
                  <v-fade-transition group>
                    <span key="clear" v-if="isCustomFiltersOn">
                      <v-btn
                        color="primary"
                        dark
                        text
                        x-small
                        title="Очистить фильтры"
                        @click="clearCustomFilters"
                      >
                        Очистить фильтры
                        <v-icon right size="18"
                          >mdi-filter-remove-outline</v-icon
                        >
                      </v-btn>
                    </span>
                  </v-fade-transition>
                </th>
              </tr>
            </thead>
          </template>

          <!-- СОДЕРЖИМОЕ ЗАГОЛОВКОВ ТАБЛИЦЫ (фильтры) -->
          <template
            v-for="head in customFilterHeaders"
            v-slot:[`header.${head.value}`]="{ header }"
          >
            <span :key="`text ${head.value}`" :title="header?.title || ''">
              <v-icon v-if="head.value === FDLF.AGREED">
                mdi-text-box-check-outline
              </v-icon>
              <span v-else>{{ header.text }}</span>
            </span>
            <SubFilterDialog
              :key="`filter ${head.value}`"
              v-model="customFilterValues"
              :tag="header.value"
              :options="header.customFilterOptions"
              :doReset="doResetSubFilters"
            />
          </template>

          <template #[`header.${FDLF.TIME_EDIT}`]="{}">
            <v-icon title="Дата изменения">mdi-calendar-month-outline</v-icon>
          </template>

          <!-- СОДЕРЖИМОЕ ПОЛЕЙ ТАБЛИЦЫ -->
          <template #[`item.index`]="{ value }">{{ value }}.</template>
          <!-- Источники в виде иконок -->
          <template #[`item.${FDLF.SOURCES}`]="{ value }">
            <v-flex v-if="storedSources?.length">
              <span
                v-for="s in storedSources"
                :key="s.short_name"
                :title="s.short_name"
                class="mr-1"
              >
                <v-icon
                  size="20"
                  :color="getSourcesIconColor(value, s.short_name)"
                >
                  {{ s.icon }}
                </v-icon>
              </span>
            </v-flex>
          </template>
          <!-- Простая подсветка поиска (не уникальная для столбцов) -->
          <template
            v-for="head in simpleHighlightHeaders"
            v-slot:[`item.${head.value}`]="{ value }"
          >
            <span
              :key="head.value"
              :inner-html.prop="value | highlight(search)"
            />
          </template>

          <template #[`item.${FDLF.EXPERT_MKB10_CODES}`]="{ item, value }">
            <v-badge
              v-if="item?.[FDLF.MKB10_ERROR_CODES]?.length"
              dot
              color="primary"
              :title="getErrorCodes(item)"
            >
              <span :inner-html.prop="value | highlight(search)" />
            </v-badge>
            <span v-else :inner-html.prop="value | highlight(search)" />
          </template>

          <template #[`item.${FDLF.AGREED}`]="{ value }">
            <v-icon :color="value ? 'green' : 'grey'">
              {{ value ? 'mdi-check' : 'mdi-minus' }}
            </v-icon>
          </template>

          <template #[`item.comment`]="{ value }">
            <v-tooltip bottom max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  :inner-html.prop="value | truncateComment | highlight(search)"
                />
              </template>
              <span :inner-html.prop="value | highlight(search)" />
            </v-tooltip>
          </template>
          <template #[`item.${FDLF.TIME_EDIT}`]="{ value }">
            <span
              :inner-html.prop="value | smartDateOrTime | highlight(search)"
              :title="value | fullDateTime"
              :class="{ 'font-weight-bold': smartIsToday(value) }"
            />
          </template>
          <!-- ПОДВАЛ ТАБЛИЦЫ -->
          <template v-slot:footer v-if="edited">
            <div class="py-2 text-right">
              <v-fab-transition>
                <v-btn
                  fab
                  dark
                  right
                  small
                  color="pink"
                  title="Создать новый критерий"
                  @click="createFilterValue"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <v-alert type="error" dense outlined>
          Произошла ошибка при работе со справочником значений фильтра:
          <br />
          {{ error_message }}
        </v-alert>
      </v-col>
    </v-row>
    <FilterFillingVaultDlg
      v-model="dialogFilterVault"
      :id="filterDataID"
      :readonly="!edited"
      :edited="edited"
      @onAction="onItemSave"
    />
    <v-menu
      v-if="isAdmin"
      v-model="showSubMenu"
      :position-x="subMenuCordX"
      :position-y="subMenuCordY"
      transition="custom-fade-transition"
      close-on-click
      absolute
      offset-y
    >
      <v-list dense>
        <v-list-item
          v-for="(item, index) in subMenuItems"
          :key="index"
          @click="item.menuAction(item.title)"
        >
          <v-list-item-icon v-if="item?.icon" class="mr-2">
            <v-icon size="20">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import orphanModule from '../../store'
import { mapGetters } from 'vuex'
import { smartIsToday, fullDateTime, smartDateOrTime } from '@/lib/date'
import { isObjectValuesEmpty } from '@/lib/objects'
import { customFilterFn } from '@/lib/customFilter'
import FilterFillingVaultDlg from './forms/content/FilterFillingVaultDlg'
import FilterCriterionDeleteDialog from './dialogs/FilterCriterionDeleteDialog'
import FilterCriterionCopyDialog from './dialogs/FilterCriterionCopyDialog'
import SubFilterDialog from './dialogs/SubFilterDialog'
import DownloadResource from './components/DownloadResource'
import FDLF from '../../config/filterDataListFields'
import {
  DEFAULT_TRUNCATE_LEN_COMMENT,
  DEFAULT_EMPTY_SOURCES_TXT,
  DEFAULT_EMPTY_AGE_TXT,
} from '../../config/constants'
import { ROUTE_404 } from '@/router/const'

export default {
  name: 'FilterDataList',
  components: {
    FilterFillingVaultDlg,
    FilterCriterionDeleteDialog,
    FilterCriterionCopyDialog,
    SubFilterDialog,
    DownloadResource,
  },
  props: {
    id: [Number, String],
  },

  data() {
    return {
      loading: true,
      error_message: '',
      selected: [],
      dialogFilterVault: false,
      filterData: {},
      search: '',
      customFilterValues: {},
      filterDataID: null,
      edited: false,
      // isCustomFiltersOn: false,
      FDLF,
      customFilterFn,
      doResetSubFilters: false,
      // таблица
      page: 1,
      itemsPerPage: 100,
      sortBy: null,
      table_footer_options: {
        itemsPerPageOptions: [100, 200, 500, 1000, -1],
      },
      headers: [
        {
          text: '№',
          value: 'index',
          width: '25px',
          sortable: false,
          cellClass: 'text--disabled cursor-pointer',
          filterable: false,
        },
        {
          value: FDLF.SOURCES,
          text: 'Источник',
          title: 'Источник (краткое наименование)',
          align: 'start',
          width: '120px',
          sortable: false,
          cellClass: 'cursor-pointer',
          class: 'color-section-1',
          // simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.SOURCES],
              this.customFilterValues[FDLF.SOURCES]
            ),
          customFilterOptions: {
            type: 'checkboxes',
            multiple: true,
            getDataFn: () => {
              return this.storedSources.map(item => {
                return {
                  value: item.short_name,
                  icon: item.icon,
                }
              })
            },
          },
        },
        {
          value: FDLF.SOURCE_DESEASE,
          text: 'Заболевание',
          title: 'Заболевание/группа по МКБ-10 (из источника)',
          align: 'start',
          cellClass: 'text--secondary font-weight-medium cursor-pointer',
          class: 'color-section-2',
          simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.SOURCE_DESEASE],
              this.customFilterValues[FDLF.SOURCE_DESEASE]
            ),
        },
        {
          value: FDLF.SOURCE_AGE_CATEGORY,
          text: 'Возраст',
          title: 'Возраст (из источника)',
          align: 'start',
          width: '150px',
          cellClass: 'text--secondary cursor-pointer',
          class: 'color-section-2',
          simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.SOURCE_AGE_CATEGORY],
              this.customFilterValues[FDLF.SOURCE_AGE_CATEGORY],
              true
            ),
          customFilterOptions: {
            type: 'checkboxes',
            multiple: false,
            getDataFn: () => {
              return this.getAgeNamesArray()
            },
          },
        },
        {
          value: FDLF.SOURCE_MKB10_CODES,
          text: 'Коды МКБ-10',
          title: 'Kод по MKБ-10 (из источника)',
          align: 'start',
          width: '200px',
          cellClass: 'text--secondary cursor-pointer text-break',
          class: 'color-section-2',
          // simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.SOURCE_MKB10_CODES],
              this.customFilterValues[FDLF.SOURCE_MKB10_CODES]
            ),
        },
        {
          value: FDLF.EXPERT_DESEASE,
          text: 'Заболевание',
          title: 'Заболевание (экспертное наименование)',
          align: 'start',
          cellClass: 'secondary--text font-weight-medium cursor-pointer',
          class: 'color-section-3',
          simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.EXPERT_DESEASE],
              this.customFilterValues[FDLF.EXPERT_DESEASE]
            ),
        },
        {
          value: FDLF.EXPERT_AGE_CATEGORY_ID,
          text: 'Возрастная группа',
          title: 'Возраст для заболевания (экспертное наименование)',
          align: 'start',
          width: '180px',
          cellClass: 'secondary--text cursor-pointer',
          class: 'color-section-3',
          simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.EXPERT_AGE_CATEGORY_ID],
              this.customFilterValues[FDLF.EXPERT_AGE_CATEGORY_ID],
              true
            ),
          customFilterOptions: {
            type: 'checkboxes',
            multiple: false,
            getDataFn: () => {
              return this.getAgeNamesArray()
            },
          },
        },
        {
          value: FDLF.EXPERT_MKB10_CODES,
          text: 'Коды МКБ-10',
          title: 'Коды МКБ-10 (из справочника)',
          align: 'start',
          width: '200px',
          cellClass: 'secondary--text cursor-pointer text-break',
          class: 'color-section-3',
          simpleHighlight: true,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.EXPERT_MKB10_CODES],
              this.customFilterValues[FDLF.EXPERT_MKB10_CODES]
            ),
        },
        // {
        //   text: 'Комментарий',
        //   align: 'center',
        //   width: '10%',
        //   value: 'comment',
        //   cellClass: 'text--secondary font-weight-medium cursor-pointer',
        // },
        {
          value: FDLF.AGREED,
          text: 'Согласовано',
          title: 'Критерий фильтра согласован экспертной стороной',
          align: 'center',
          width: '80px',
          cellClass: 'cursor-pointer',
          filterable: false,
          sortable: false,
          customFilter: item =>
            this.customFilterFn(
              item[FDLF.AGREED],
              this.customFilterValues[FDLF.AGREED],
              false,
              'Согласовано'
            ),
          customFilterOptions: {
            type: 'checkboxes',
            multiple: false,
            getDataFn: () => {
              // true - 1 значение, false - 2ое значение
              return ['Согласовано', 'Не согласовано']
            },
          },
        },
        {
          value: FDLF.TIME_EDIT,
          text: 'Дата изменения',
          align: 'end',
          width: '85px',
          cellClass: 'text--disabled font-weight-medium cursor-pointer',
        },
      ],
      subMenuItem: {},
      showSubMenu: false,
      subMenuCordX: 0,
      subMenuCordY: 0,
      subMenuItems: [
        {
          // копия и сразу открыть ( иначе её не найти )
          title: 'Создать копию',
          icon: 'mdi-content-copy',
          menuAction: action => {
            this.someFunc(action)
          },
        },
        {
          title: 'Может удалить?',
          icon: 'mdi-checkbox-multiple-blank-outline',
          menuAction: action => {
            alert(action)
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...orphanModule.mapGetters([
      'filterDataList',
      'storedAgeCategoriesDict',
      'storedFilter',
      'storedFilterName',
      'storedSources',
      'loadingFilterDataValues',
      'canReadFilters',
      'canEditFilters',
    ]),
    hideFooter() {
      return this.filterDataList?.length <= 10
    },
    simpleHighlightHeaders() {
      return this.headers.filter(el => el?.simpleHighlight)
    },
    customFilterHeaders() {
      return this.headers.filter(el => el?.customFilter)
    },
    isCustomFiltersOn() {
      return !isObjectValuesEmpty(this.customFilterValues)
    },
    itemsList() {
      if (!this.filterDataList?.length) return []

      // Не вижу варианта сделать CustomFilter для преобразовнных полей..
      // Поэтому - делаю преобразование всего списка в текст
      const list = []
      this.filterDataList.forEach((data, index) => {
        const element = { index: index + 1 }
        for (const key in data) {
          switch (key) {
            case FDLF.SOURCES:
              element[key] = this.getShortSorcesName(data[key])
              break
            case FDLF.EXPERT_AGE_CATEGORY_ID:
              element[key] = this.getAgeNames(data[key])
              break
            default:
              element[key] = data[key]
              break
          }
        }
        list.push(element)
      })

      const conditions = []
      for (const field in this.customFilterValues) {
        if (this.customFilterValues[field]) {
          const row = this.headers.filter(el => el.value === field)
          if (row?.length === 1 && typeof row[0]?.customFilter === 'function') {
            conditions.push(row[0].customFilter)
          }
        }
      }

      if (conditions.length > 0) {
        return list.filter(criterion => {
          return conditions.every(condition => {
            return condition(criterion)
          })
        })
      }

      return list
    },
    filteredIds() {
      return this.itemsList.map(el => el.id)
    },
    showActionsBtn() {
      return !!this.selected?.length
    },
    updateTime() {
      if (this.storedFilter?.time_edit) {
        return new Date(this.storedFilter.time_edit).toLocaleDateString()
      } else return new Date().toLocaleDateString()
    },
  },

  created() {
    this.CLEAR_STORED_FILTER_DATA_LIST()
  },

  mounted() {
    this.init(Number(this.id))
  },

  beforeDestroy() {
    this.CLEAR_STORED_FILTER_DATA_LIST()
  },

  watch: {
    id: 'init',
    showSubMenu: 'subMenuChangeState',
  },

  filters: {
    truncateComment(str = '') {
      return str?.length && str.length > DEFAULT_TRUNCATE_LEN_COMMENT
        ? str.substring(0, DEFAULT_TRUNCATE_LEN_COMMENT) + '...'
        : str
    },

    fullDateTime,
    smartDateOrTime,
  },

  methods: {
    ...orphanModule.mapActions([
      'GET_AGE_CATEGORIES',
      'GET_FILTER_DATA_LIST',
      'CLEAR_STORED_FILTER_DATA_LIST',
      'GET_FILTER',
    ]),
    smartIsToday,

    async init(id) {
      if (isNaN(+id) || !id) {
        this.$router.push({ name: ROUTE_404 }).catch(() => null)
        return
      }

      this.loading = true
      this.error_message = ''
      try {
        await Promise.all([
          // словарь возрастов
          this.GET_AGE_CATEGORIES(),
          // для отображения имени фильтра
          this.GET_FILTER(id),
          // список критериев
          this.GET_FILTER_DATA_LIST({ id, extra: true }),
        ])
      } catch (err) {
        this.error_message = err
      } finally {
        this.loading = false
      }
    },
    getShortSorcesName(data = []) {
      if (Array.isArray(data)) {
        if (!data?.length) return DEFAULT_EMPTY_SOURCES_TXT

        return data.map(el => el.short_name).join(', ')
      }

      return data.short_name
    },

    getAgeNames(id = 0) {
      if (!this.storedAgeCategoriesDict?.length) return ''
      if (!id) return DEFAULT_EMPTY_AGE_TXT

      return (
        this.storedAgeCategoriesDict.find(el => el.id == id).title ||
        DEFAULT_EMPTY_AGE_TXT
      )
    },

    getAgeNamesArray() {
      const arr = this.storedAgeCategoriesDict.map(el => el.title)
      arr.push(DEFAULT_EMPTY_AGE_TXT)
      return arr
    },

    createFilterValue() {
      this.openFilterVaultDialog(null)
    },

    onItemSave() {
      if (!this.filterDataID) this.goToEndPage()
    },
    goToEndPage() {
      if (this.itemsPerPage < 0 || !!this.sortBy) return

      const np = this.itemsList?.length % this.itemsPerPage > 0
      this.page =
        Math.trunc(this.itemsList?.length / this.itemsPerPage) + (np ? 1 : 0)
    },

    editFilterValues(item) {
      if (this.canEditFilters || this.canReadFilters) {
        const criterion = this.filterDataList[item.index - 1]
        this.selected = [criterion] // выбрать редактируемый
        this.openFilterVaultDialog(criterion)
      }
    },

    openFilterVaultDialog(criterion = {}) {
      this.filterData = {
        filter_id: this.id,
        user_creator_id: criterion?.user_creator_id || null,
      }
      this.filterDataID = criterion?.id || null
      this.dialogFilterVault = true
    },

    onCloseActionDialog() {
      this.selected = []
    },

    clearCustomFilters() {
      this.doResetSubFilters = true
      this.$nextTick(() => {
        this.doResetSubFilters = false
        this.customFilterValues = {}
      })
    },

    getSourcesIconColor(value = '', name = '') {
      if (!value) return 'grey lighten-2'
      const strArr = value.split(', ')
      if (
        this.search &&
        strArr.includes(name) &&
        name.toUpperCase().includes(this.search.toUpperCase())
      )
        return 'primary'
      if (strArr.includes(name)) return 'green darken-3'
      return 'grey lighten-2'
    },

    rowClass(item) {
      const style = item?.[FDLF.MKB10_ERROR_CODES]?.length
        ? 'row-style-error'
        : ''

      return this.subMenuItem?.id === item?.id ? 'row-style-select' : style
    },

    subMenuChangeState(val) {
      if (!val) {
        this.subMenuItem = null
      }
    },

    onBlurDTable() {
      this.subMenuItem = null
    },

    getErrorCodes(item = []) {
      const codes = item?.[FDLF.MKB10_ERROR_CODES].join(' / ')
      return `Содержит устаревшие коды: ${codes}`
    },

    rightClickHandler(e, { item }) {
      if (!this.edited) return

      e.preventDefault()
      this.showSubMenu = false
      this.subMenuCordX = e.clientX
      this.subMenuCordY = e.clientY

      this.$nextTick(() => {
        this.showSubMenu = true
        console.log(item)
        this.subMenuItem = item
      })
    },

    someFunc(action) {
      console.log(action)
    },
  },
}
</script>

<style lang="scss" scoped>
.centered-start.col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.centered-end.col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.group-header {
  th {
    height: 40px !important;
    border: none !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: rgba(232, 162, 47, 0.05);
  }
  hr {
    border: 1px dashed rgba(0, 0, 0, 0.12);
  }
}
::v-deep {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 8px;
  }
}

.source_card {
  background-color: rgba(60, 179, 113, 0.05) !important;
}
.expert_card {
  background-color: rgba(106, 90, 205, 0.05) !important;
}
.comment_card {
  background-color: rgba(232, 162, 47, 0.05) !important;
}

::v-deep {
  .v-data-table-header {
    .color-section-1 {
      background-color: rgba(232, 162, 47, 0.05);
      padding: 0 10px;
    }
    .color-section-2 {
      background-color: rgba(60, 179, 113, 0.05);
      padding: 0 10px;
    }
    .color-section-3 {
      background-color: rgba(106, 90, 205, 0.05);
      padding: 0 10px;
    }
    th {
      height: 32px !important;
      background-color: rgba(232, 162, 47, 0.05);
    }
  }
}
::v-deep .row-style-select {
  background-color: lavenderblush; // snow
}
::v-deep .row-style-error {
  background-color: mistyrose; //beige;
}
::v-deep .v-input--selection-controls {
  margin-top: 4px;
  padding-top: 4px;
}
::v-deep .custom-field.v-text-field {
  padding-top: 2px;
  margin-top: 4px;
}
.pre-wrap {
  white-space: pre-wrap;
}
.fs12 {
  font-size: 0.8rem;
}
::v-deep .v-badge--dot .v-badge__badge {
  border-radius: 3.5px;
  height: 7px;
  width: 7px;
}
</style>
