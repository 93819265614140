import api from '@/lib/axiosApi'

export class EApiFilterDataException extends Error {
  constructor(message) {
    super(`[filterData] ${message}`)
    this.name = 'EApiFilterDataException'
  }
  static check(values) {
    if (!values) {
      throw new this('Критерий фильтра не может быть пустым')
    }
  }
  static checkId(val) {
    if ((typeof val === 'object' && !val?.id) || !val) {
      throw new this('Критерий фильтра не определён (отсутствует id)')
    }
  }
  static checkIds(ids) {
    if (!ids?.length) {
      throw new this(
        'Не опрределен(ы) критерий(и) фильтра для удалления (отсутствует список id)'
      )
    }
  }
}

export const filterData = {
  getFilterDataList: (id, extra = false) => {
    EApiFilterDataException.checkId(id)
    return api.get(`orphan/filter-data/by-filter/${id}${extra ? '?extra' : ''}`)
  },

  getFilterData: id => {
    EApiFilterDataException.checkId(id)
    return api.get(`orphan/filter-data/${id}?extra`)
  },

  getFilterExcept: id => {
    return api.get(`/orphan/filter-data/${id}/except`)
  },

  createFilterExcept: (id, values) => {
    return api.patch(`/orphan/filter-data/${id}/except`, { exceptList: values })
  },

  createFilterData: values => {
    EApiFilterDataException.check(values)
    return api.post('orphan/filter-data/', values)
  },

  editFilterData: values => {
    EApiFilterDataException.checkId(values)
    debugger
    return api.patch(`orphan/filter-data/${values.id}`, values)
  },

  changeFilterStatus: (id, checked) => {
    EApiFilterDataException.checkId(id)
    return api.post(`orphan/filter-data/${id}/${checked ? 'check' : 'uncheck'}`)
  },

  bulkDeleteFilterData: (id, ids) => {
    EApiFilterDataException.checkId(id)
    EApiFilterDataException.checkIds(ids)
    return api.delete(`orphan/filter-data/by-filter/${id}`, { ids })
  },
}

export default filterData
