<template>
  <v-container fluid>
    <!-- строка заголовок -->
    <v-row class="mt-0">
      <v-col
        class="pa-0"
        :class="{ 'shake error--text': hasState && hasError }"
      >
        <v-label
          :color="validationState"
          :dark="dark"
          :light="light"
          :disabled="isDisabled"
          :focused="hasState"
        >
          <span class="text-subtitle-2">Источники:</span>
        </v-label>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="secondary"
          rounded
          striped
        />
      </v-col>
    </v-row>
    <!-- строка данных -->
    <v-row class="mt-0">
      <v-col v-if="errorSourcesDict">
        <v-alert type="error" dense outlined>
          Произошла ошибка в работы со словарём источников{{
            hasErrorValueMissing &&
            `, возможно в справочнике потеряны значения [${errorValueMissing}]`
          }}
        </v-alert>
      </v-col>
      <v-col
        v-else-if="items?.length"
        v-for="el in items"
        :key="el.id"
        cols="12"
        sm="6"
        md="3"
      >
        <v-checkbox
          v-model="asArrayInternalValue"
          :prepend-icon="el.icon"
          :label="el.short_name"
          :value="el"
          :value-comparator="compareById"
          :title="el.full_name"
          :disabled="isDisabled"
          :readonly="isReadonly"
          :color="computedColor"
          :error="hasState && hasError"
          :success="hasSuccess"
          hide-details
          class="mt-0"
        />
      </v-col>
      <v-col v-else>
        <span class="text--disabled text-body-2">
          ( нет данных для отображения )
        </span>
      </v-col>
    </v-row>
    <!-- Строка сообщений  -->
    <v-row v-if="showDetails">
      <v-col class="pa-0">
        <v-messages
          :value="validationTarget"
          :color="validationState"
          :dark="dark"
          :light="light"
          :role="hasMessages ? 'alert' : null"
          class="mt-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Validatable from 'vuetify/lib/mixins/validatable'

export default {
  name: 'SourcesEditor',
  mixins: [Validatable],
  inheritAttrs: false,
  props: {
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDetails() {
      return (
        this.hideDetails === false ||
        (this.hideDetails === 'auto' && this.hasDetails)
      )
    },
    values() {
      return this.value ? [this.value].flat() : []
    },
    errorSourcesDict() {
      return (
        !this.loading &&
        (!this.items ||
          this.values?.length > this.items?.length ||
          // пришли значение которых нет в справочнике
          this.errorValueMissing?.length)
      )
    },
    errorValueMissing() {
      // пришли значение которых нет в справочнике
      return this.loading
        ? null
        : this.values
            .filter(v => !this.items?.find(el => this.compareById(el, v)))
            .map(el => el.short_name)
    },

    hasErrorValueMissing() {
      return !!this.errorValueMissing?.length
    },
    // делаем всегда массив
    asArrayInternalValue: {
      get() {
        return this.asArray(this.internalValue)
      },
      set(val) {
        if (this.single) {
          if (val?.length > 1) val.shift()
          this.internalValue = this.asArray(val)[0] ?? null
        } else {
          // всегда упорядочем по id
          this.internalValue = this.asArray(val).sort((a, b) => a?.id - b?.id)
        }
      },
    },
  },

  methods: {
    compareById: (a, b) => a?.id === b?.id,
    asArray: val => (val ? (Array.isArray(val) ? val : [val]) : []),
  },
}
</script>

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
