<template>
  <v-container>
    <v-row v-if="canCreateFilters">
      <v-spacer />
      <v-col sm="6" md="4" class="text-right mb-2">
        <FilterEditorDialog append>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              width="300"
              color="secondary"
              outlined
            >
              Создать новый фильтр
            </v-btn>
          </template>
        </FilterEditorDialog>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col offset-md="1" md="10">
        <v-data-table
          :headers="showsHeaders"
          :items="storedFiltersList"
          :loading="loading"
          :items-per-page="-1"
          disable-pagination
          hide-default-footer
          loading-text="Подготовка списка фильтров..."
          no-results-text="Фильтры не найдены"
        >
          <template #[`item.index`]="{ index }">
            <span>{{ index + 1 }}.</span>
          </template>

          <template #[`item.name`]="{ index, item }">
            <div class="pt-2">
              <span class="secondary--text font-weight-medium"
                >{{ index + 1 }}.
                <router-link
                  :to="{ name: 'FilterData', params: { id: item.id } }"
                  class="secondary--text"
                  >{{ item.name }}</router-link
                ></span
              >
            </div>
            <div class="pb-2 pl-4">
              <span
                class="text--disabled text-subtitle-2"
                :title="item.description | hintTruncateStr"
                >{{ item.description | truncateStr }}</span
              >
            </div>
          </template>

          <template #[`item.time_create`]="{ value }">
            <span
              :title="value | fullDateTime"
              :class="{ 'secondary--text': smartIsToday(value) }"
              >{{ value | smartDateOrTime }}</span
            >
          </template>

          <template #[`item.time_edit`]="{ value }">
            <span
              :title="value | fullDateTime"
              :class="{ 'secondary--text': smartIsToday(value) }"
              >{{ value | smartDateOrTime }}</span
            >
          </template>

          <template #[`item.actions`]="{ item }" v-if="canCreateFilters">
            <div class="d-flex justify-end actions">
              <FilterEditorDialog :filter="item">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon dense color="indigo">
                    <v-icon size="18">mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
              </FilterEditorDialog>
              <FilterDeleteDialog :filter="item">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon dense color="red">
                    <v-icon size="18">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </FilterDeleteDialog>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import orphanModule from '../../store'
import { smartIsToday, fullDateTime, smartDateOrTime } from '@/lib/date'
import FilterEditorDialog from './dialogs/FilterEditorDialog'
import FilterDeleteDialog from './dialogs/FilterDeleteDialog'

import { DEFAULT_TRUNCATE_LEN } from '../../config/constants'

export default {
  name: 'FiltersList',
  components: { FilterEditorDialog, FilterDeleteDialog },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Наименование фильтра',
          value: 'name',
          cellClass: 'text-subtitle-1',
        },
        {
          text: 'Дата изменения',
          align: 'end',
          value: 'time_edit',
          cellClass: 'text--disabled font-weight-medium',
          width: '150px',
        },
        {
          text: 'Действия',
          value: 'actions',
          width: '150px',
          align: 'end',
          sortable: false,
        },
      ],
      error_message: '',
    }
  },
  computed: {
    ...orphanModule.mapGetters([
      'storedFiltersList',
      'canReadFilters',
      'canEditFilters',
      'canCreateFilters',
    ]),
    showsHeaders() {
      return this.headers.filter(
        ({ value }) => value !== 'actions' || this.canCreateFilters
      )
    },
  },

  async mounted() {
    this.loading = true
    try {
      await this.GET_FILTERS()
    } finally {
      this.loading = false
    }
  },

  filters: {
    truncateStr(str = '') {
      return str?.length > DEFAULT_TRUNCATE_LEN
        ? str.substring(0, DEFAULT_TRUNCATE_LEN) + '...'
        : str
    },
    hintTruncateStr(str = '') {
      return str?.length > DEFAULT_TRUNCATE_LEN ? str : ''
    },
    fullDateTime,
    smartDateOrTime,
  },
  methods: {
    ...orphanModule.mapActions(['GET_FILTERS']),
    smartIsToday,
  },
}
</script>

<style scoped>
.v-data-table >>> tr .actions {
  display: none !important;
}
.v-data-table >>> tr:hover .actions {
  display: flex !important;
}
</style>
