<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    title="ВНИМАНИЕ!!!"
    label="Вы действительно хотите удалить фильтр ?"
    colorBtnClose="blue darken-1"
    labelBtnClose="ОТМЕНИТЬ"
    colorBtnAction="red darken-1"
    labelBtnAction="УДАЛИТЬ"
    @onAction="deleteFilterConfirm"
  >
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #text>
      <span class="font-weight-bold">"{{ filterName }}"</span>
      <p v-if="filterDescription" class="mt-2 text--disabled">
        {{ filterDescription }}
      </p>
    </template>
  </ActionDialog>
</template>

<script>
import orphanModule from '../../../store'
import ActionDialog from '@/components/ui/dialogs/ActionDialog.vue'

export default {
  name: 'FilterDeleteDialog',
  components: { ActionDialog },
  data: () => ({
    deleting: false,
  }),
  props: {
    filter: {
      type: Object,
      required: false,
    },
  },
  computed: {
    filterName() {
      return this.filter?.name || ''
    },
    filterDescription() {
      return this.filter?.description || ''
    },
  },
  methods: {
    ...orphanModule.mapActions(['DELETE_FILTER']),
    async deleteFilterConfirm() {
      if (!this.filter?.id) return
      this.deleting = true
      try {
        await this.DELETE_FILTER(this.filter)
        this.$toast.success('Фильтр удалён успешно!')
      } finally {
        this.deleting = false
      }
    },
  },
}
</script>
