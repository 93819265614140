// Поля таблицы списка критериев фильтра
const FDLF = {
  // 'Источник'
  SOURCES: 'source',
  SOURCES_ID: 'source_id',
  // 'Заболевание/группа по МКБ-10'
  SOURCE_DESEASE: 'source_desease',
  // Возраст
  SOURCE_AGE_CATEGORY: 'source_age_category',
  // 'Коды МКБ'
  SOURCE_MKB10_CODES: 'source_mkb10_code',
  // 'Заболевание'
  EXPERT_DESEASE: 'expert_desease',
  // ИД Возраста экспертное
  EXPERT_AGE_CATEGORY_ID: 'expert_age_category_id',
  // 'Коды МКБ (из справочника)'
  EXPERT_MKB10_CODES: 'expert_mkb10_codes',
  // Поле Согласовано
  AGREED: 'is_checked',
  // Время редактирования
  TIME_EDIT: 'time_edit',
  // Комментарий
  COMMENT: 'comment',
  // Объект выбранного возраста (приходит с бэка)
  EXPERT_AGE_CATEGORY: 'expert_age_category',
  // Валидные MKB10 коды
  MKB10_CODES: 'mkb10_codes',
  // Ошибочные коды (приходит с бэка при спец флаге - extra)
  MKB10_ERROR_CODES: 'mkb10_error_codes',
  // Исключение
  HAS_EXCEPT: 'hasExcept',
}

export const SOURCE_AGE_CATEGORY_DEFAULT = 'Не указано'

export const CREATE_FDLF_OBJ = () => ({
  // источник
  [FDLF.SOURCES]: null,
  [FDLF.SOURCES_ID]: null,
  // исходное
  [FDLF.SOURCE_DESEASE]: '',
  [FDLF.SOURCE_AGE_CATEGORY]: '',
  [FDLF.SOURCE_MKB10_CODES]: '',
  // экспертное
  [FDLF.EXPERT_DESEASE]: null,
  [FDLF.EXPERT_AGE_CATEGORY_ID]: null,
  [FDLF.EXPERT_MKB10_CODES]: null,
  [FDLF.COMMENT]: null,
  [FDLF.HAS_EXCEPT]: false,
})

export default FDLF
