<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    title="ВНИМАНИЕ!!!"
    :label="criterionLabel"
    colorBtnClose="blue darken-1"
    colorBtnAction="red darken-1"
    labelBtnClose="ОТМЕНИТЬ"
    labelBtnAction="УДАЛИТЬ"
    @onAction="deleteFilterCriterionConfirm"
  >
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template #text>
      <span v-if="name" class="font-weight-bold">{{ criterionName }}</span>
      <p v-if="criterionDescription" class="mt-2 text--disabled">
        {{ criterionDescription }}
      </p>
    </template>
  </ActionDialog>
</template>

<script>
import orphanModule from '../../../store'
import ActionDialog from '@/components/ui/dialogs/ActionDialog.vue'

export default {
  name: 'FilterCriterionDeleteDialog',
  components: { ActionDialog },
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    criteria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    deleting: false,
  }),

  computed: {
    criterionName() {
      return `Фильтр: "${this.name || ''}"`
    },
    criterionDescription() {
      if (!this.criteria?.length) return ''

      const candidates = this.criteria.map(el => el.id)
      return this.criteria?.length === 1
        ? `Критерий №: ${this.criteria[0].id}`
        : `Критерии № ${candidates.join(', № ')}`
    },
    criterionLabel() {
      return `Вы действительно хотите удалить критери${
        this.criteria?.length === 1 ? 'й' : 'и'
      } фильтра ?`
    },
  },
  methods: {
    ...orphanModule.mapActions(['BULK_DELETE_FILTER_VALUES']),
    async deleteFilterCriterionConfirm() {
      if (!this.criteria?.length) return

      const ids = this.criteria.map(el => el.id)
      this.deleting = true
      try {
        await this.BULK_DELETE_FILTER_VALUES(ids)
        this.$toast.success(
          `Критери${ids.length ? 'и' : 'й'} фильтра удалён${
            ids.length ? 'ы' : ''
          } успешно!`
        )
      } finally {
        this.deleting = false
        this.$emit('onClose')
      }
    },
  },
}
</script>
