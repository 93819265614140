<template>
  <v-menu
    ref="menuSubFilter"
    offset-y
    :close-on-content-click="false"
    v-model="isOpened"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon plain v-bind="attrs" v-on="on">
        <v-icon small :color="isNotEmptyValue ? 'primary' : ''">
          mdi-filter
        </v-icon>
      </v-btn>
    </template>

    <v-container
      style="background-color: white; width: 250px"
      fluid
      class="pa-0"
    >
      <v-card tile flat>
        <v-text-field
          v-if="!type"
          :value="value[tag]"
          @input="doChange"
          class="pa-3 pt-4"
          type="text"
          label="Фильтровать по:"
          clearable
          hide-details
          :autofocus="true"
        ></v-text-field>

        <!-- возвращает выбранные ID -->
        <v-list v-if="type === 'checkboxes' && filedsCheckboxes?.length">
          <v-list-item-group
            v-model="selectedCheckboxes"
            @change="doChange"
            :multiple="multiple"
            active-class="no-active"
          >
            <template v-for="(f, index) in filedsCheckboxes">
              <v-list-item :key="index" :value="f?.value || f" dense>
                <template v-slot:default="{ active }">
                  <v-list-item-icon v-if="f?.icon" class="mr-3">
                    <v-icon>{{ f.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{
                      f?.value || f
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      dense
                      :input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-btn
            v-if="isNotEmptyValue"
            @click="$refs.menuSubFilter.save()"
            small
            text
            color="red darken-1"
            >Применить</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="isNotEmptyValue"
            @click="clearVal"
            small
            text
            color="blue darken-1"
            >Очистить</v-btn
          >
          <v-btn
            v-else
            @click="$refs.menuSubFilter.save()"
            small
            text
            color="blue darken-1"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-menu>
</template>
<script>
export default {
  name: 'SubFilterDialog',
  props: {
    value: { type: Object, require: true },
    tag: { type: String, require: true },
    options: { type: Object, require: false, default: () => {} },
    doReset: { type: Boolean, default: false },
  },
  data() {
    return {
      isOpened: false,
      selectedCheckboxes: [],
      filedsCheckboxes: [],
      type: '',
      multiple: false,
      getDataFn: null,
    }
  },
  watch: {
    isOpened: function (val) {
      const {
        type = '',
        multiple = false,
        getDataFn = null,
      } = this.options || {}

      this.type = type
      this.multiple = multiple

      if (typeof getDataFn === 'function' && val) {
        switch (this.type) {
          case 'checkboxes':
            this.filedsCheckboxes = getDataFn()
            break

          default:
            break
        }
      }
    },
    doReset: function (val) {
      if (val) {
        this.clearVal()
      }
    },
  },

  computed: {
    isNotEmptyValue() {
      if (!this.value?.[this.tag]) return false
      return (
        (Array.isArray(this.value?.[this.tag]) &&
          this.value[this.tag].length) ||
        (typeof this.value?.[this.tag] === 'string' && this.value?.[this.tag])
      )
    },
  },

  methods: {
    doChange(newValue) {
      if (newValue !== this.value[this.tag]) {
        this.$emit('input', { ...this.value, [this.tag]: newValue })
      }
    },
    clearVal() {
      let clearVal = ''
      switch (this.type) {
        case 'checkboxes':
          clearVal = []
          this.selectedCheckboxes = []
          break

        default:
          break
      }

      delete this.value[this.tag]
      this.$emit('input', { ...this.value, [this.tag]: clearVal })
    },
  },
}
</script>

<style>
.no-active.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__action {
  align-self: center;
  margin: 8px 0;
}
</style>
