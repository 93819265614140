import Vue from 'vue'
import api from '../api'
import { STATUS } from '@/store'

export class EApiFilterDataException extends Error {
  constructor(message) {
    super(`[filterData] ${message}`)
    this.name = 'EApiFilterDataException'
  }
  static raiseIfLoading(getters) {
    if (getters.loadingFilterDataValues !== STATUS.IDLE)
      throw new this(
        `Критерии фильтра находится на стадии - ${getters.loadingFilterDataValues}`
      )
  }
}

export default {
  state: () => ({
    loadingFilterDataList: false,
    loadingFilterDataValues: STATUS.IDLE,
    filter_id: null, // id списка фильтра = storedFilterId
    filterDataList: [], // Список доступных критериев фильтра
    filterDataValues: null, // Активный критерий фильтра
  }),

  getters: {
    loadingFilterDataList: state => state.loadingFilterDataList,
    loadingFilterDataValues: state => state.loadingFilterDataValues,

    filterDataList: state => state.filterDataList,
    filterExpertDeseaseDict: state =>
      [
        ...new Set(
          state.filterDataList?.map(({ expert_desease }) => expert_desease)
        ).values(),
      ].sort(),

    filterDataValues: state => state.filterDataValues,
    filterDataValuesId: state => state.filterDataValues?.id || null,
    // разрешаем апрувить только существующие не свои критерии
    filterDataСanAgreed: (_, getters) =>
      getters.filterDataValuesId &&
      (getters.filterDataValues?.user_creator_id !== getters.userID ||
        getters.isAdmin),
    filterDataAgreed: (_, getters) => getters.filterDataValues?.is_checked,
  },

  mutations: {
    // Список Критериев фильра
    SET_DEFAULT_FILTER_DATA_LIST(state) {
      state.filterDataList = []
      state.filter_id = null
    },
    SET_EXCEPT_STATUS(state, val) {
      state.filterDataValues.hasExcept = !!val
    },

    SET_LOADING_FILTER_DATA_LIST(state, st) {
      state.loadingFilterDataList = !!st
    },

    SET_FILTER_DATA_LIST(state, { filter_id, list }) {
      state.filter_id = filter_id
      state.filterDataList = list || []
    },

    DELETE_FILTER_DATA(state, ids) {
      state.filterDataList = state.filterDataList.filter(
        el => !ids.includes(+el.id)
      )
    },

    // Список Значений критерия фильтра
    SET_DEFAULT_FILTER_VALUES(state) {
      state.filterDataValues = null
    },

    SET_LOADING_FILTER_VALUES(state, st = STATUS.IDLE) {
      state.loadingFilterDataValues = st
    },

    SET_FILTER_DATA_VALUES(state, values) {
      state.filterDataValues = values
      if (!values) return

      const dataListIndex = state.filterDataList?.findIndex(
        ({ id }) => id === values.id
      )
      if (dataListIndex > -1) {
        Vue.set(state.filterDataList, dataListIndex, {
          ...state.filterDataList[dataListIndex],
          ...values,
        })
      } else {
        state.filterDataList.push(values)
      }
    },
    SET_FILTER_PRE_DATA_VALUES(state, id) {
      if (!id) return
      const index = state.filterDataList?.findIndex(el => el.id === id)
      if (index > -1) {
        // нашли предварительно
        state.filterDataValues = state.filterDataList[index]
      }
    },
  },

  actions: {
    // загрузить все фильтры
    async GET_FILTER_DATA_LIST(
      { commit, getters },
      { id: filter_id, no_def = false, extra = false }
    ) {
      if (!filter_id) return

      try {
        if (getters.loadingFilterDataList)
          throw new Error(
            'Списки критериев фильтров находится на стадии загрузки!'
          )

        if (!no_def) {
          commit('SET_DEFAULT_FILTER_DATA_LIST')
        }
        commit('SET_LOADING_FILTER_DATA_LIST', true)

        const list = await api.getFilterDataList(filter_id, extra)
        commit('SET_FILTER_DATA_LIST', { filter_id, list })
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'GET_FILTER_DATA_LIST',
            text: `Ошибка получения списка критериев фильтра с id: ${filter_id}`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER_DATA_LIST', false)
      }
    },

    async GET_FILTER_EXCEPT({ commit }, id) {
      try {
        return await api.getFilterExcept(id)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'GET_FILTER_EXCEPT',
            text: `Ошибка загрузки списка исключений`,
            err,
          },
          { root: true }
        )
        throw err
      }
    },

    async CREATE_FILTER_EXCEPT({ commit }, { id, exceptList }) {
      try {
        return await api.createFilterExcept(id, exceptList)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'SET_FILTER_EXCEPT',
            text: `Ошибка обновления значений исключений`,
            err,
          },
          { root: true }
        )
        throw err
      }
    },

    async GET_FILTER_VALUES({ commit, getters }, id) {
      // если нет Id то чистим
      if (!id) {
        commit('SET_DEFAULT_FILTER_VALUES')
        return
      }
      // Не загружать если есть уже в сторе
      if (getters.filterDataValuesId == id) {
        return
      }
      try {
        EApiFilterDataException.raiseIfLoading(getters)

        commit('SET_LOADING_FILTER_VALUES', STATUS.LOADING)
        // найдём прошлое значение в списке
        commit('SET_FILTER_PRE_DATA_VALUES', id)

        const data = await api.getFilterData(id)

        commit('SET_FILTER_DATA_VALUES', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'GET_FILTER_VALUES',
            text: `Ошибка загрузки критерия фильтра`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER_VALUES', STATUS.IDLE)
      }
    },

    async CREATE_FILTER_VALUES({ commit, getters, state }, values) {
      try {
        EApiFilterDataException.raiseIfLoading(getters)

        commit('SET_LOADING_FILTER_VALUES', STATUS.CREATING)
        commit('SET_FILTER_DATA_VALUES', null)
        const filter_id = state.filter_id
        const data = await api.createFilterData({ filter_id, ...values })

        commit('SET_FILTER_DATA_VALUES', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'CREATE_FILTER_VALUES',
            text: 'Ошибка создания нового критерия фильтра',
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER_VALUES', STATUS.IDLE)
      }
    },

    async UPDATE_FILTER_VALUES({ getters, commit }, values) {
      try {
        EApiFilterDataException.raiseIfLoading(getters)

        commit('SET_LOADING_FILTER_VALUES', STATUS.UPDATING)
        const data = await api.editFilterData(values)

        commit('SET_FILTER_DATA_VALUES', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'UPDATE_FILTER_VALUES',
            text: `Ошибка обновления нового критерия фильтра с id: ${values?.id}`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER_VALUES', STATUS.IDLE)
      }
    },

    async BULK_DELETE_FILTER_VALUES({ commit, dispatch, getters }, ids = {}) {
      try {
        EApiFilterDataException.raiseIfLoading(getters)

        commit('SET_LOADING_FILTER_VALUES', STATUS.DELETING)

        await api.bulkDeleteFilterData(getters.storedFilterId, ids)
        commit('DELETE_FILTER_DATA', ids)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'BULK_DELETE_FILTER_VALUES',
            text: `Ошибка удаления критерия(ев) фильтра с id: ${ids.join(
              ', '
            )}`,
            err,
          },
          { root: true }
        )
        dispatch('GET_FILTER_DATA_LIST', { id: getters.storedFilterId })
        throw err
      } finally {
        commit('SET_LOADING_FILTER_VALUES', STATUS.IDLE)
      }
    },

    async CHANGE_FILTER_STATUS({ commit, getters }, checked) {
      if (!getters.filterDataСanAgreed) return
      const id = getters.filterDataValuesId
      try {
        EApiFilterDataException.raiseIfLoading(getters)

        commit('SET_LOADING_FILTER_VALUES', STATUS.CHANGE)
        const data = await api.changeFilterStatus(id, checked)

        commit('SET_FILTER_DATA_VALUES', data)
      } catch (err) {
        commit(
          'SET_ERROR',
          {
            head: 'CHANGE_FILTER_STATUS',
            text: `Ошибка изменения статуса критерия фильтра с id: ${id}`,
            err,
          },
          { root: true }
        )
        throw err
      } finally {
        commit('SET_LOADING_FILTER_VALUES', STATUS.IDLE)
      }
    },

    CLEAR_STORED_FILTER_DATA_LIST({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA_LIST')
    },

    CLEAR_STORED_FILTER_VALUES({ commit }) {
      commit('SET_DEFAULT_FILTER_VALUES')
    },
  },
}
