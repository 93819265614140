import { createNamespacedHelpers } from 'vuex'
import { registerModule, unregisterModule } from '@/store'
import { orphanModule } from './const'

import filters from './filters'

export default {
  name: orphanModule,
  register: () =>
    registerModule(orphanModule, { ...filters, namespaced: true }),
  unregister: () => unregisterModule(orphanModule),
  // map store
  ...createNamespacedHelpers(orphanModule),
}
